<template>
  <div class="detalhesBox">
    <div class="linhasDetalhes">
      <div
        class="titles sb-2 sb-right"
        :id="'B7RankingDetalhes-0'"
        :class="{
          'disabled-title': !linhaValida,
        }"
      >
        Razão Social
      </div>
      <div
        :class="{
          'disabled-border': !linhaValida,
        }"
        class="results sb-9"
      >
        {{
          formatarTexto(
            resultados[`bk_103_004_empresa_${index + 1}_des_razao_social`]
          )
        }}
      </div>
    </div>
    <div class="linhasDetalhes">
      <div
        class="titles sb-2 sb-right"
        :id="'B7RankingDetalhes-1'"
        :class="{
          'disabled-title': !linhaValida,
        }"
      >
        Nome Fantasia
      </div>
      <div
        :class="{
          'disabled-border': !linhaValida,
        }"
        class="results sb-9"
      >
        {{
          formatarTexto(
            resultados[`bk_103_004_empresa_${index + 1}_des_nome_fantasia`]
          )
        }}
      </div>
    </div>
    <div class="linhasDetalhes">
      <div
        class="titles sb-2 sb-right"
        :id="'B7RankingDetalhes-2'"
        :class="{
          'disabled-title': !linhaValida,
        }"
      >
        CNAE Principal
      </div>
      <div
        :class="{
          'disabled-border': !linhaValida,
        }"
        class="results sb-right sb-4"
      >
        {{ resultados[`bk_103_004_empresa_${index + 1}_cod_cnae_principal`] }}
      </div>
      <div
        class="titles sb-1 sb-right"
        :id="'B7RankingDetalhes-3'"
        :class="{
          'disabled-title': !linhaValida,
        }"
      >
        Concent. CNAE
      </div>
      <div
        :class="{
          'disabled-border': !linhaValida,
        }"
        class="results sb-1 sb-right"
      >
        {{
          resultados[`bk_103_004_empresa_${index + 1}_qtd_concentracao_cnae`]
        }}
      </div>
      <div
        class="titles sb-2 sb-right"
        :id="'B7RankingDetalhes-4'"
        :class="{
          'disabled-title': !linhaValida,
        }"
      >
        Qtde CNAE's Secundários
      </div>
      <div
        :class="{
          'disabled-border': !linhaValida,
        }"
        class="results sb-1"
      >
        {{
          resultados[`bk_103_004_empresa_${index + 1}_qtd_cnaes_secundarios`]
        }}
      </div>
    </div>
    <div class="linhasDetalhes">
      <div class="rankCol sb-3 sb-right">
        <div
          class="titles sb-3"
          :id="'B7RankingDetalhes-5'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Tipo de Empresa
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-top"
        >
          <span
            v-for="(opcao, j) in [
              'Ltda',
              'SA',
              'MEI',
              'Eireli',
              'EI',
              'SS',
              'Outro',
            ]"
            :key="j"
            class="tabText"
            :class="{
              selecionado: compararValores(
                resultados[`bk_103_004_empresa_${index + 1}_des_tipo_empresa`],
                opcao
              ),
              'nao-selecionado': !compararValores(
                resultados[`bk_103_004_empresa_${index + 1}_des_tipo_empresa`],
                opcao
              ),
            }"
            :id="'B7RankingDetalhes-5-' + j"
          >
            <p class="tabTexts">
              {{ opcao }}
            </p>
            <span v-if="j < 6" style="color: black">|</span>
          </span>
        </div>
      </div>
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-6'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Check Sócio Adm.
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          <Check
            :valor="
              resultados[`bk_103_004_empresa_${index + 1}_flag_socio_admin`]
            "
          />
        </div>
      </div>
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-7'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Ano de Fundação
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          {{ resultados[`bk_103_004_empresa_${index + 1}_num_ano_fundacao`] }}
        </div>
      </div>
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-8'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Tempo Fundação
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          {{ resultados[`bk_103_004_empresa_${index + 1}_tmp_fundacao_anos`] }}
          <span
            v-if="
              resultados[`bk_103_004_empresa_${index + 1}_tmp_fundacao_anos`] ==
              1
            "
            style="margin-left: 3px"
          >
            ano
          </span>
          <span
            v-if="
              resultados[`bk_103_004_empresa_${index + 1}_tmp_fundacao_anos`] >
              1
            "
            style="margin-left: 3px"
          >
            anos
          </span>
        </div>
      </div>
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-9'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Ano de Vínculo
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          {{ resultados[`bk_103_004_empresa_${index + 1}_num_ano_vinculo`] }}
        </div>
      </div>
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-10'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Tempo Vínculo
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          {{ resultados[`bk_103_004_empresa_${index + 1}_tmp_vinculo_anos`] }}
          <span
            v-if="
              resultados[`bk_103_004_empresa_${index + 1}_tmp_vinculo_anos`] ==
              1
            "
            style="margin-left: 3px"
          >
            ano
          </span>
          <span
            v-if="
              resultados[`bk_103_004_empresa_${index + 1}_tmp_vinculo_anos`] > 1
            "
            style="margin-left: 3px"
          >
            anos
          </span>
        </div>
      </div>
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-11'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Simples Nacional
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          <Check
            :valor="
              resultados[
                `bk_103_004_empresa_${index + 1}_flag_optante_simples_nacional`
              ]
            "
          />
        </div>
      </div>
      <div class="rankCol sb-2">
        <div
          class="titles fundo-escuro sb-2"
          :id="'B7RankingDetalhes-12'"
          :class="{
            'disabled-title': !linhaValida,
          }"
          style="font-weight: 700"
        >
          Faturamento Presumido
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
            fatur:
              !loadingFaturamento &&
              this.resultadoFaturamento == null &&
              linhaValida &&
              !telaHistorico &&
              !gerandoPdf,
          }"
          class="results sb-top sb-2"
          :id="'resultado-faturamento' + index"
        >
          <button
            class="button_sub"
            v-if="
              !loadingFaturamento &&
              this.resultadoFaturamento == null &&
              linhaValida &&
              !telaHistorico &&
              !gerandoPdf
            "
            @click="
              carregarFaturamento(
                resultados[`bk_103_004_empresa_${index + 1}_cnpj`]
              )
            "
          >
            Consultar
            <span class="material-symbols-outlined fatur-icon">
              saved_search
            </span>
          </button>
          <span
            class="fatur-aviso"
            v-if="
              linhaValida &&
              resultadoFaturamento == null &&
              (gerandoPdf || telaHistorico)
            "
          >
            Não consultado
          </span>
          <md-progress-spinner
            v-if="loadingFaturamento && resultadoFaturamento == null"
            :md-diameter="30"
            :md-stroke="2"
            md-mode="indeterminate"
          >
          </md-progress-spinner>
          <span v-if="!loadingFaturamento && resultadoFaturamento != null">
            {{
              formatarValorParaReais(
                resultadoFaturamento.atributos.faturamento_presumido
              )
            }}
          </span>

          <span v-if="!linhaValida">-</span>
        </div>
        <b-tooltip
          v-if="resultadoFaturamento != null && exibirTootipsConsultas"
          :target="'resultado-faturamento' + index"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tt-text"
          boundary="window"
        >
          <span
            v-if="resultadoFaturamento.atributos.faturamento_presumido == -1"
          >
            {{ resultadoFaturamento.atributos.elegibilidade }} <br />
          </span>
          <span
            v-if="resultadoFaturamento.atributos.faturamento_presumido > 999999"
          >
            Estimativa anual: <br />
            R$
            {{
              formatarNumeroComPonto(
                resultadoFaturamento.atributos.faturamento_presumido
              )
            }},00
          </span>
        </b-tooltip>
      </div>
    </div>
    <div class="linhasDetalhes">
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles"
          :id="'B7RankingDetalhes-13'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Total de Filiais
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-top"
        >
          {{ resultados[`bk_103_004_empresa_${index + 1}_tqtd_total_filiais`] }}
        </div>
      </div>
      <div class="rankCol sb-3 sb-right">
        <div
          class="titles sb-3"
          :id="'B7RankingDetalhes-14'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Extensão
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-3 sb-top"
        >
          <span
            v-for="(opcao, j) in ['Local ', 'Regional', 'Nacional']"
            :key="j"
            class="tabText"
            :class="{
              selecionado: compararValores(
                resultados[
                  `bk_103_004_empresa_${index + 1}_des_extensao_territorial`
                ],
                opcao
              ),
              'nao-selecionado': !compararValores(
                resultados[
                  `bk_103_004_empresa_${index + 1}_des_extensao_territorial`
                ],
                opcao
              ),
            }"
            :id="'B7RankingDetalhes-14-' + j"
          >
            <p class="tabTexts">
              {{ opcao }}
            </p>
            <span v-if="j < 2" style="color: black">|</span>
          </span>
        </div>
      </div>
      <div class="rankCol sb-2 sb-right">
        <div
          class="titles sb-2"
          :id="'B7RankingDetalhes-15'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Porte
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-2 sb-top"
        >
          <span
            v-for="(opcao, j) in ['MEI', 'ME ', 'EPP', 'Médio+']"
            :key="j"
            class="tabText"
            :class="{
              selecionado: compararValores(
                resultados[`bk_103_004_empresa_${index + 1}_des_porte`],
                opcao
              ),

              'nao-selecionado': !compararValores(
                resultados[`bk_103_004_empresa_${index + 1}_des_porte`],
                opcao
              ),
            }"
            :id="'B7RankingDetalhes-15-' + j"
          >
            <p class="tabTexts">
              {{ opcao }}
            </p>
            <span v-if="j < 3" style="color: black">|</span>
          </span>
        </div>
      </div>
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-16'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Qtde de Sócios
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          {{ resultados[`bk_103_004_empresa_${index + 1}_qtd_socios`] }}
        </div>
      </div>
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-17'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Alteração QSA
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          <Check
            :valor="
              resultados[`bk_103_004_empresa_${index + 1}_flag_alteracao_qsa`]
            "
          />
        </div>
      </div>
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-18'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Recência QSA
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          {{
            formatarData(
              resultados[`bk_103_004_empresa_${index + 1}_dt_recencia_qsa`]
            )
          }}
        </div>
      </div>
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-19'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Recência Status Ativa
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          {{
            formatarData(
              resultados[
                `bk_103_004_empresa_${index + 1}_dt_recencia_status_ativa`
              ]
            )
          }}
        </div>
      </div>
      <div class="rankCol sb-1">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-20'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Origem no Exterior
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          <Check
            :valor="
              resultados[`bk_103_004_empresa_${index + 1}_flag_origem_exterior`]
            "
          />
        </div>
      </div>
    </div>
    <div class="linhasDetalhes">
      <table>
        <thead>
          <tr class="tableHead">
            <th
              v-for="(column, index) in tableColumns"
              :key="column"
              class="titles"
              :class="{
                'sb-1': index == 3 || index == 4,
                'sb-2': index == 0 || index == 2,
                'sb-5': index == 1,
                'sb-right': index != 4,
                'disabled-title': !linhaValida,
              }"
              :id="'B7RankingDetalhes-21-' + index"
            >
              {{ column }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(detalhamento, index) in detalhamentos"
            :key="index"
            class="tableBody"
          >
            <td
              :class="{
                'disabled-border': !linhaValida,
              }"
              class="results detail sb-2 sb-right sb-top"
              :id="'B7RankingDetalhes-22-' + index"
            >
              {{ detalhamento.valor }}
            </td>
            <td
              :class="{
                'disabled-border': !linhaValida,
              }"
              class="results detail sb-5 sb-right sb-top"
            >
              {{ detalhamento.desc }}
            </td>
            <td
              :class="{
                'disabled-border': !linhaValida,
              }"
              class="results detail sb-2 sb-right sb-top"
            >
              {{ formatarData(detalhamento.data) }}
            </td>
            <td
              :class="{
                'disabled-border': !linhaValida,
              }"
              class="results detail sb-1 sb-right sb-top"
            >
              {{ detalhamento.tempo }}
              <span v-if="detalhamento.tempo == 1" style="margin-left: 3px">
                ano
              </span>
              <span v-if="detalhamento.tempo > 1" style="margin-left: 3px">
                anos
              </span>
            </td>
            <td
              :class="{
                'disabled-border': !linhaValida,
              }"
              class="results detail sb-1 sb-top"
            >
              <Check :valor="detalhamento.check" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="linhasDetalhes">
      <div class="rankCol sb-2 sb-right">
        <div
          class="titles sb-2"
          :id="'B7RankingDetalhes-23'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          CEP da Matriz
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-2 sb-top"
        >
          {{
            formatarCEP(
              resultados[`bk_103_004_empresa_${index + 1}_num_cep_matriz`]
            )
          }}
        </div>
      </div>
      <div class="rankCol sb-5 sb-right">
        <div
          class="titles sb-5"
          :id="'B7RankingDetalhes-24'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Logradouro
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb5 sb-top desc"
        >
          {{
            resultados[`bk_103_004_empresa_${index + 1}_des_logradouro_matriz`]
          }}
        </div>
      </div>
      <div class="rankCol sb-3 sb-right">
        <div
          class="titles sb-3"
          :id="'B7RankingDetalhes-25'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Município
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-3 sb-top"
        >
          {{
            resultados[`bk_103_004_empresa_${index + 1}_des_municipio_matriz`]
          }}
        </div>
      </div>
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-26'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          UF
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          {{ resultados[`bk_103_004_empresa_${index + 1}_des_uf_matriz`] }}
        </div>
      </div>
    </div>
    <div class="linhasDetalhes">
      <div class="rankCol sb-2 sb-right">
        <div
          class="titles"
          style="padding-inline: 30px"
          :id="'B7RankingDetalhes-27'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Precisão do CEP da Matriz
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-top"
        >
          <span
            v-for="(opcao, j) in ['Único', 'Parcial', 'Múltiplo']"
            :key="j"
            class="tabText"
            :class="{
              selecionado: compararValores(
                resultados[
                  `bk_103_004_empresa_${index + 1}_precisao_cep_matriz`
                ],
                opcao
              ),

              'nao-selecionado': !compararValores(
                resultados[
                  `bk_103_004_empresa_${index + 1}_precisao_cep_matriz`
                ],
                opcao
              ),
            }"
            :id="'B7RankingDetalhes-27-' + j"
          >
            <p class="tabTexts">
              {{ opcao }}
            </p>
            <span v-if="j < 2" style="color: black">|</span>
          </span>
        </div>
      </div>
      <div class="rankCol sb-5 sb-right">
        <div
          class="titles sb-5"
          :id="'B7RankingDetalhes-28'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Classe do CEP da Matriz
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-top"
        >
          <span
            v-for="(opcao, j) in [
              'Padrão',
              'Especial',
              'Promocional',
              'Comunitário',
              'Correios',
            ]"
            :key="j"
            class="tabText"
            :class="{
              selecionado: compararValores(
                resultados[`bk_103_004_empresa_${index + 1}_classe_cep_matriz`],
                opcao
              ),

              'nao-selecionado': !compararValores(
                resultados[`bk_103_004_empresa_${index + 1}_classe_cep_matriz`],
                opcao
              ),
            }"
            :id="'B7RankingDetalhes-28-' + j"
          >
            <p class="tabTexts">
              {{ opcao }}
            </p>
            <span v-if="j < 4" style="color: black">|</span>
          </span>
        </div>
      </div>
      <div class="rankCol sb-right sb-1">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-29'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Dist. ao CEP Princ.
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          {{
            resultados[`bk_103_004_empresa_${index + 1}_dist_km_cep_principal`]
          }}
        </div>
      </div>
      <div class="rankCol sb-right sb-1">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-30'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Viés QSA Familiar
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          <Check
            :valor="
              resultados[
                `bk_103_004_empresa_${index + 1}_flag_qsa_vies_familiar`
              ]
            "
          />
        </div>
      </div>
      <div class="rankCol sb-right sb-1">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-31'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Match c/ UF do CPF
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          <Check
            :valor="
              resultados[
                `bk_103_004_empresa_${index + 1}_flag_match_uf_cpf_matriz`
              ]
            "
          />
        </div>
      </div>
      <div class="rankCols b-1">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-32'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Match c/ Região
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          <Check
            :valor="
              resultados[
                `bk_103_004_empresa_${index + 1}_flag_match_regiao_cpf_matriz`
              ]
            "
          />
        </div>
      </div>
    </div>
    <div class="linhasDetalhes">
      <div class="rankCol sb-2 sb-right">
        <div
          class="titles sb-2"
          style="padding: 1px 25px"
          :id="'B7RankingDetalhes-33'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Distância ao Polo do CNAE
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-2 sb-top"
        >
          {{
            resultados[`bk_103_004_empresa_${index + 1}_distancia_polo_cnae_km`]
          }}
        </div>
      </div>
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-34'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Concent. CNAE Polo
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          {{
            resultados[
              `bk_103_004_empresa_${index + 1}_qtd_concentracao_polo_cnae`
            ]
          }}
        </div>
      </div>
      <div class="rankCol sb-5 sb-right">
        <div
          class="titles sb-5"
          :id="'B7RankingDetalhes-35'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Município do Polo
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-5 sb-top"
        >
          {{
            resultados[
              `bk_103_004_empresa_${index + 1}_des_municipio_polo_cnae`
            ]
          }}
        </div>
      </div>
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-36'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          UF do Polo
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          {{ resultados[`bk_103_004_empresa_${index + 1}_des_uf_polo_cnae`] }}
        </div>
      </div>

      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-37'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Match c/ UF Polo
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          <Check
            :valor="
              resultados[
                `bk_103_004_empresa_${index + 1}_flag_match_uf_polo_cnae_matriz`
              ]
            "
          />
        </div>
      </div>
      <div class="rankCol sb-1 sb-right">
        <div
          class="titles sb-1"
          :id="'B7RankingDetalhes-38'"
          :class="{
            'disabled-title': !linhaValida,
          }"
        >
          Match c/ Região Polo
        </div>
        <div
          :class="{
            'disabled-border': !linhaValida,
          }"
          class="results sb-1 sb-top"
        >
          <Check
            :valor="
              resultados[
                `bk_103_004_empresa_${
                  index + 1
                }_flag_match_regiao_polo_cnae_matriz`
              ]
            "
          />
        </div>
      </div>
    </div>
    <div v-for="(tt, i) in tooltipsBloco7" :key="i">
      <b-tooltip
        v-if="index == 0 && exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import Check from "@/components/SuperBox/Check.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";
import { consultasService } from "@/services";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapActions, mapWritableState } from "pinia";
import { userPreferenceStore } from "@/stores/userPreference";

export default {
  name: "B7RankingDetalhes",

  components: { Check },

  props: {
    item: Object,
    index: Number,
    resultados: Object,
    resultadoFaturamentoLinha: Object,
    linhaValida: Boolean,
    codigo_agrupador: String,
    gerandoPdf: Boolean,
    exibirTootipsConsultas: Boolean,
    valorDataRef: String,
  },

  data() {
    return {
      detalhamentos: [
        {
          valor: "Situação Cadastral",
          desc: this.resultados[
            `bk_103_004_empresa_${this.index + 1}_des_situacao_cadastral`
          ],
          data: this.resultados[
            `bk_103_004_empresa_${this.index + 1}_dt_situacao_cadastral`
          ],
          tempo:
            this.resultados[
              `bk_103_004_empresa_${this.index + 1}_tmp_situacao_cadastral_anos`
            ],
          check:
            this.resultados[
              `bk_103_004_empresa_${
                this.index + 1
              }_flag_alerta_risco_situacao_cadastral`
            ],
        },
        {
          valor: "Motivo Situação",
          desc: this.resultados[
            `bk_103_004_empresa_${this.index + 1}_des_motivo_situacao`
          ],
          data: this.resultados[
            `bk_103_004_empresa_${this.index + 1}_dt_motivo_situacao`
          ],
          tempo:
            this.resultados[
              `bk_103_004_empresa_${this.index + 1}_tmp_motivo_situacao_anos`
            ],
          check:
            this.resultados[
              `bk_103_004_empresa_${
                this.index + 1
              }_flag_alerta_risco_motivo_situacao`
            ],
        },
        {
          valor: "Situação Especial",
          desc: this.resultados[
            `bk_103_004_empresa_${this.index + 1}_des_situacao_especial`
          ],
          data: this.resultados[
            `bk_103_004_empresa_${this.index + 1}_dt_situacao_especial`
          ],
          tempo:
            this.resultados[
              `bk_103_004_empresa_${this.index + 1}_tmp_situacao_especial_anos`
            ],
          check:
            this.resultados[
              `bk_103_004_empresa_${
                this.index + 1
              }_flag_alerta_risco_situacao_especial`
            ],
        },
      ],
      tableColumns: [
        "Detalhamentos",
        "Descritivo da Situação",
        "Data da Situação",
        "Tempo Situação",
        "Alerta Risco",
      ],

      loadingFaturamento: false,
      resultadoFaturamento: null,
    };
  },

  methods: {
    async carregarFaturamento(cnpj) {
      if (this.linhaValida) {
        this.loadingFaturamento = true;
        const objetoConsulta = {
          cep: "",
          tipo_pessoa: "pessoa_juridica",
          cpf_cnpj: cnpj,
          cod_ref: "BOXPRPJ002",
          data_ref: this.valorDataRef,
          modo_resultado: "codigo",
          parametros_adicionais: {},
          identificador_etiqueta_primaria: "PRD",
          identificadores_etiquetas_secundarias: "",
          cod_agrupador: this.codigo_agrupador,
        };

        await consultasService
          .realizaConsultaModelo(objetoConsulta)
          .then((resultadoModelo) => {
            this.resultadoFaturamento = resultadoModelo;
          });

        if (this.consultasTrilha.length > 1) {
          const consultaAtual = this.consultasTrilha.find(
            (consulta) =>
              consulta.cod_agrupador == this.trilhaSelecionada.cod_agrupador
          );

          consultaAtual.historico.contexto[0].resultados.BOXPRPJ002 =
            this.resultadoFaturamento;
        }

        this.loadingFaturamento = false;
      }
    },

    formatarValorParaReais(valor) {
      if (valor != -1) {
        if (Math.abs(valor) >= 1000000000) {
          const valorEmBilhoes = (valor / 1000000000).toFixed(2);
          if (parseFloat(valorEmBilhoes) >= 2) {
            return (
              `${parseFloat(valorEmBilhoes).toLocaleString("pt-BR")}` +
              " bilhões"
            );
          } else {
            return (
              `${parseFloat(valorEmBilhoes).toLocaleString("pt-BR")}` +
              " bilhão"
            );
          }
        } else if (Math.abs(valor) >= 1000000) {
          const valorEmMilhoes = (valor / 1000000).toFixed(2);
          if (parseFloat(valorEmMilhoes) >= 2) {
            return (
              `${parseFloat(valorEmMilhoes).toLocaleString("pt-BR")}` +
              " milhões"
            );
          } else {
            return (
              `${parseFloat(valorEmMilhoes).toLocaleString("pt-BR")}` +
              " milhão"
            );
          }
        } else {
          return `${parseFloat(valor)
            .toFixed(2)
            .replace(".", ",")
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")}`;
        }
      } else {
        return valor;
      }
    },

    formatarData(dataString) {
      if (dataString != null && dataString != "" && dataString != "-") {
        const data = new Date(dataString);
        const ano = data.getFullYear();
        const mes = (data.getMonth() + 1).toString().padStart(2, "0");
        const dia = data.getDate().toString().padStart(2, "0");

        return `${dia}/${mes}/${ano}`;
      } else {
        return dataString;
      }
    },

    formatarCEP(numero) {
      if (numero != "-") {
        numero = String(numero);
        let cepLimpo = numero.replace(/\D/g, "");

        if (cepLimpo.length !== 8) {
          return numero;
        }

        return numero.replace(/^(\d{5})(\d{3})$/, "$1-$2");
      } else {
        return numero;
      }
    },

    formatarNumeroComPonto(numero) {
      if (numero != "-") {
        const numeroString = numero.toString().replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },

    formatarTexto(texto) {
      if (texto != null && texto != "-") {
        if (this.ocultarDados) {
          texto = texto.replace(/[^ ]/g, "*");
        }
      }

      return texto;
    },

    compararValores(str1, str2) {
      const removerAcentos = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };

      const prepararString = (str) => {
        return str.trim().toLowerCase();
      };

      return (
        prepararString(removerAcentos(str1)) ===
        prepararString(removerAcentos(str2))
      );
    },
  },

  computed: {
    tooltipsBloco7() {
      return listaTooltips.bloco07_ranking2;
    },

    telaHistorico() {
      return this.$route.path.includes("/historico");
    },

    ...mapWritableState(dadosConsultaAddStore, {
      consultasTrilha: "consultasTrilha",
      trilhaSelecionada: "trilhaSelecionada",
    }),

    ...mapWritableState(userPreferenceStore, {
      ocultarDados: "ocultarDados",
    }),
  },

  mounted() {
    if (this.resultadoFaturamentoLinha != null) {
      this.resultadoFaturamento = this.resultadoFaturamentoLinha.Consulta;
    } else {
      this.resultadoFaturamento = null;
    }
  },
};
</script>

<style lang="scss" scoped>
.detalhesBox {
  display: flex;
  flex-direction: column;
  cursor: default;
}

.linhasDetalhes {
  display: flex;
  flex-direction: row;
  margin: 5px 0 0 50px;
}

.rankCol {
  padding: 0;
  display: flex;
  flex-direction: column;
  line-height: 16px;
}
.titles {
  padding: 1px 5px;
  background-color: #d9e1f2;
  border-radius: 5px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  cursor: default;
  line-height: 14px;

  @include flex-center(column);

  &.fundo-escuro {
    background-color: #7030a0;
    color: white;

    &.disabled-title {
      background-color: #b9b9b9 !important;
    }
  }
}
.results {
  height: 40px;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  cursor: default;
  padding: 5px;
  line-height: 14px;

  &.detail {
    border-bottom-width: 1px !important;
  }

  &.fatur {
    border: none;
  }
}

.tabText {
  display: flex;
  margin: 0 !important;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  word-break: normal;

  .tabTexts {
    padding: 0 5px;
    margin: 0 !important;
  }
}

.selecionado {
  color: #68a490;
  margin-inline: 3px;
  font-weight: 600;
}
.nao-selecionado {
  color: #a6a6a6;
  margin-inline: 3px;
}

.tableHead {
  display: flex;
}

.tableBody {
  display: flex;
}

.disabled-title {
  background-color: #d9d9d9;
  color: white;
}

.disabled-border {
  color: #a7a7a7;
  border: solid 1px #a7a7a7;
}

.fatur-link {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 5px;
  margin: 0 0 0 15px;
  color: #0070c0;
}

.button_sub {
  width: 149px;
  height: 36px;
  font-size: 11px;
  padding: 1px 5px;
  color: white;
  border: none;
  background-image: url(/img/tela-inicial/Botao-Nova.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  .fatur-icon {
    transform: rotateY(180deg);
    font-size: 21px;
  }
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
