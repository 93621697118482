<template>
  <div class="resultsBox">
    <div class="resultLine">
      <div
        class="boxId sb-1"
        :class="{
          'disabled-title': !linhaValida,
        }"
      >
        0{{ index + 1 }}
      </div>
      <div
        class="linhaRank"
        style="width: 130px"
        :class="{
          'disabled-text': !linhaValida,
        }"
      >
        -------------------
      </div>
      <div
        class="titles sb-2 sb-right"
        :id="'B7RankingLinhas-0'"
        :class="{
          'disabled-title': !linhaValida,
        }"
      >
        CNPJ
      </div>
      <div
        class="results sb-3 sb-right text-send"
        v-if="
          resultados[`bk_103_004_empresa_${index + 1}_cnpj`] != undefined &&
          resultados[`bk_103_004_empresa_${index + 1}_cnpj`] != '-'
        "
      >
        {{ formatarCNPJ(resultados[`bk_103_004_empresa_${index + 1}_cnpj`]) }}
        <span
          v-if="
            !telaHistorico &&
            !resultados[`bk_103_004_empresa_${index + 1}_cnpj`].includes('*') &&
            !gerandoPdf
          "
          class="material-symbols-outlined icon-send"
          @click="
            selecionaConsultaAdicional(
              resultados[`bk_103_004_empresa_${index + 1}_cnpj`]
            )
          "
        >
          send
        </span>
      </div>
      <div class="results sb-3 sb-right disabled-border" v-else>
        Sem informações
      </div>
      <div
        class="titles sb-2"
        :id="'B7RankingLinhas-1'"
        :class="{
          'disabled-title': !linhaValida,
        }"
      >
        Capital Social
      </div>
      <div
        class="results sb-2"
        v-if="
          resultados[`bk_103_004_empresa_${index + 1}_vlr_capital_social`] !=
            undefined &&
          resultados[`bk_103_004_empresa_${index + 1}_vlr_capital_social`] !=
            '-'
        "
      >
        {{
          formatarNumeroComPonto(
            resultados[`bk_103_004_empresa_${index + 1}_vlr_capital_social`]
          )
        }}
      </div>
      <div class="results sb-2 disabled-border" v-else>-</div>

      <div class="arrowBox" @click="alternarDetalhes()">
        <img
          :src="iconArrow"
          :class="{
            'icon-arrow-aberto': arrowControl,
            'icon-arrow': !arrowControl,
            'btn-disabled':
              (resultados[`bk_103_004_empresa_${index + 1}_cnpj`] ==
                undefined ||
                resultados[`bk_103_004_empresa_${index + 1}_cnpj`] == '-') &&
              this.index != 0,
          }"
        />
      </div>
    </div>
    <div class="detalhes" v-if="arrowControl">
      <B7RankingDetalhes
        :item="item"
        :index="index"
        :resultados="resultados"
        :linhaValida="linhaValida"
        :codigo_agrupador="codigo_agrupador"
        :resultadoFaturamentoLinha="resultadoFaturamentoLinha"
        :gerandoPdf="gerandoPdf"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        :valorDataRef="valorDataRef"
      />
      <B7RankingSocios
        :item="item"
        :index="index"
        :resultados="resultados"
        :linhaValida="linhaValida"
        :gerandoPdf="gerandoPdf"
        :exibirTootipsConsultas="exibirTootipsConsultas"
      />
    </div>
    <div v-for="(tt, i) in tooltipsBloco7" :key="i">
      <b-tooltip
        v-if="index == 0 && exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import B7RankingDetalhes from "@/components/SuperBox/B7RankingDetalhes.vue";
import B7RankingSocios from "@/components/SuperBox/B7RankingSocios.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";
import { userPreferenceStore } from "@/stores/userPreference";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapActions, mapWritableState } from "pinia";

export default {
  name: "B7RankingLinhas",

  components: {
    B7RankingDetalhes,
    B7RankingSocios,
  },

  props: {
    item: Object,
    index: Number,
    resultados: Object,
    codigo_agrupador: String,
    resultadosFaturamento: Array,
    gerandoPdf: Boolean,
    exibirTootipsConsultas: Boolean,
    valorDataRef: String,
  },

  data() {
    return {
      iconArrow: "/img/superbox/arrow-bottom.png",
      arrowControl: false,
      resultadoFaturamentoLinha: {},
    };
  },

  methods: {
    selecionaConsultaAdicional(documento) {
      this.abrirSelecaoConsulta(documento, "PJ");
    },

    fechaConsultaAdicional() {
      this.fecharSelecaoConsulta();
    },

    formatarNumeroComPonto(numero) {
      if (numero != "-") {
        const numeroString = numero.toString().replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },

    formatarCNPJ(cnpj) {
      if (cnpj != null && cnpj != "-") {
        cnpj = cnpj.replace(/\D/g, "");
        cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
        if (this.ocultarDados) {
          cnpj = cnpj.replace(
            /(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})\-(\d{2})/,
            (match, p1, p2, p3, p4, p5) => `${p1}.***.***/****-${p5}`
          );
        }
      }

      return cnpj;
    },

    alternarDetalhes() {
      if (
        (this.resultados[`bk_103_004_empresa_${this.index + 1}_cnpj`] !=
          undefined &&
          this.resultados[`bk_103_004_empresa_${this.index + 1}_cnpj`] !=
            "-") ||
        this.index == 0
      ) {
        if (this.arrowControl) {
          this.arrowControl = false;
        } else {
          this.arrowControl = true;
        }
      }
    },

    verificaFaturamento() {
      const cnpjLinha =
        this.resultados[`bk_103_004_empresa_${this.index + 1}_cnpj`];
      var resultadoEncontrado;
      if (this.resultadosFaturamento != null) {
        resultadoEncontrado = this.resultadosFaturamento.find(
          (resultado) =>
            resultado.Consulta.sumario_consulta.parametros.cnpj === cnpjLinha
        );
      }

      if (resultadoEncontrado) {
        this.resultadoFaturamentoLinha = resultadoEncontrado;
      } else {
        this.resultadoFaturamentoLinha = null;
      }
    },

    ...mapActions(dadosConsultaAddStore, [
      "abrirSelecaoConsulta",
      "fecharSelecaoConsulta",
    ]),
  },

  computed: {
    telaHistorico() {
      return this.$route.path.includes("/historico");
    },

    tooltipsBloco7() {
      return listaTooltips.bloco07_ranking1;
    },

    linhaValida() {
      if (
        this.resultados[`bk_103_004_empresa_${this.index + 1}_cnpj`] ==
          undefined ||
        this.resultados[`bk_103_004_empresa_${this.index + 1}_cnpj`] == "-"
      ) {
        return false;
      } else if (
        this.resultados[`bk_103_004_empresa_${this.index + 1}_cnpj`] !=
          undefined &&
        this.resultados[`bk_103_004_empresa_${this.index + 1}_cnpj`] != "-"
      ) {
        return true;
      } else {
        return false;
      }
    },

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      ocultarDados: "ocultarDados",
    }),

    ...mapWritableState(dadosConsultaAddStore, {
      ativaTrilhaConsultas: "ativaTrilhaConsultas",
    }),
  },

  mounted() {
    if (this.index == 0) {
      this.arrowControl = true;
    }

    this.verificaFaturamento();
  },
};
</script>

<style lang="scss" scoped>
.resultLine {
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: default;
}

.boxId {
  display: flex;
  height: 40px;
  width: 80px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  background-color: #2f75b5;
  color: white;
  margin: 5px 5px 0 0;

  @include flex-center(column);
}

.linhaRank {
  font-weight: 800;
  color: #0070c0;
  overflow: hidden;
  white-space: nowrap;
  margin: 0 0px 0px 0px;
  @include flex-center(column);
  cursor: default;
}

.titles {
  padding: 1px 10px;
  margin: 0 0 0px 5px;
  background-color: #d9e1f2;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  margin: 5px 5px 0 0;
  line-height: 14px;

  @include flex-center(column);
}

.results {
  height: 40px;
  margin: 5px 0px 0 0;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  padding: 5px;
  word-break: break-word;
  line-height: 14px;
}

.arrowBox {
  width: 25px;
  cursor: pointer;

  @include flex-center(column);

  .icon-arrow {
    width: 15px;
  }
  .icon-arrow-aberto {
    width: 15px;
    transform: rotate(180deg);
  }
}
.btn-disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5;
}

.detalhes {
  padding: 0 0 15px 0;
  margin: 0 0 10px 0;
  border-bottom: dashed 1px #0070c0;
}

.text-send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
}

.icon-send {
  color: #0070c0;
  width: 24px;
  font-size: 20px;
  cursor: pointer;
  transition: font-size 0.3s;

  &:hover {
    font-size: 22px;
  }
}

.disabled-title {
  background-color: #d9d9d9;
  color: white;
}
.disabled-text {
  color: #a7a7a7;
}
.disabled-border {
  color: #a7a7a7;
  border: solid 1px #a7a7a7;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
