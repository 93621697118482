<template>
  <div class="boxSocios">
    <div class="tabelapf">
      <div class="linhaCabec">
        <div
          class="cabec"
          v-for="(cab, j) in [
            'Top 05 Sócios PF',
            'CPF',
            'Nome',
            'Tempo Vínculo',
            'Check Sócio Adm.',
            'Relação Familiar',
            'Match CEP',
            'Semelhança Sobrenome',
          ]"
          :key="cab"
          :class="{
            'sb-1': j != 2 && j != 1,
            'sb-2': j == 1,
            'sb-3': j == 2,
            'sb-right': j != 7,
            'disabled-title': !linhaValida,
          }"
          :id="'B7RankingSocios-0-' + j"
        >
          {{ cab }}
        </div>
      </div>
      <div class="corpo">
        <div class="linhas" v-for="(socioPf, i) in valoresSociosPF" :key="i">
          <div class="principal">
            <div
              class="results titulo sb-1 sb-right sb-top"
              :class="{
                index0: i == 0,
                desativado: !socioPf.cpf || socioPf.cpf == '-',
              }"
              :id="'B7RankingSocios-1'"
            >
              Sócio PF {{ i }}
            </div>
            <div
              class="results sb-2 sb-right sb-top"
              :class="{
                'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
              }"
            >
              <span v-if="socioPf.cpf && socioPf.cpf != '-'" class="text-send">
                {{ formatarCPF(socioPf.cpf) }}
                <span
                  v-if="
                    !telaHistorico && !socioPf.cpf.includes('*') && !gerandoPdf
                  "
                  class="material-symbols-outlined icon-send"
                  @click="selecionaConsultaAdicional(socioPf.cpf)"
                >
                  send
                </span>
              </span>
              <span v-if="!socioPf.cpf || socioPf.cpf == '-'">
                Sem informações
              </span>
            </div>
            <div
              class="results sb-3 sb-right sb-top"
              :class="{
                'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
              }"
            >
              {{ formatarNome(socioPf.nome) }}
            </div>
            <div
              class="results sb-1 sb-right sb-top"
              :class="{
                'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
              }"
            >
              {{ socioPf.tempo }}
              <span v-if="socioPf.tempo == 1" style="margin-left: 3px">
                ano
              </span>
              <span
                v-if="socioPf.tempo != '-' && socioPf.tempo > 1"
                style="margin-left: 3px"
              >
                anos
              </span>
            </div>
            <div
              class="results sb-1 sb-right sb-top"
              :class="{
                'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
              }"
            >
              <Check
                :valor="socioPf.checkAdm"
                :class="{
                  'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
                }"
              />
            </div>
            <div
              class="results sb-1 sb-right sb-top"
              :class="{
                'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
              }"
            >
              <Check :valor="socioPf.relacao" v-if="i != 0" />
              <span v-else>-</span>
            </div>
            <div
              class="results sb-1 sb-right sb-top"
              :class="{
                'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
              }"
            >
              <Check :valor="socioPf.matchCep" v-if="i != 0" />
              <span v-else>-</span>
            </div>
            <div
              class="results sb-1 sb-right sb-top"
              :class="{
                'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
              }"
            >
              <span v-if="socioPf.cpf != '-'"> {{ socioPf.semelhanca }}% </span>
              <span v-else> - </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabelapj">
      <div class="linhaCabec">
        <div
          class="cabec verde"
          v-for="(cab, j) in [
            'Top 05 Sócios PJ',
            'CNPJ',
            'CNAE Principal',
            'Tempo Vínculo',
            'Tempo Fundação',
            'Tipo',
            'Capital Social',
            'Alerta de Situação',
          ]"
          :class="{
            'sb-1': j != 2 && j != 1,
            'sb-2': j == 1,
            'sb-3': j == 2,
            'sb-right': j != 7,
            'disabled-title': !linhaValida,
            vermel: j == 7,
          }"
          :key="cab"
          :id="'B7RankingSocios-2-' + j"
        >
          {{ cab }}
        </div>
      </div>
      <div class="corpo">
        <B7RankingSociosLinhas
          v-for="(item, indexPj) in Array(5)"
          :key="indexPj"
          :item="item"
          :index="index"
          :indexPj="indexPj"
          :resultados="resultados"
          :gerandoPdf="gerandoPdf"
          :exibirTootipsConsultas="exibirTootipsConsultas"
        />
      </div>
    </div>
    <div v-for="(tt, i) in tooltipsBloco7" :key="i">
      <b-tooltip
        v-if="index == 0 && exibirTootipsConsultas"
        :target="tt.target"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { mapActions, mapWritableState } from "pinia";
import Check from "@/components/SuperBox/Check.vue";
import B7RankingSociosLinhas from "@/components/SuperBox/B7RankingSociosLinhas.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "B7RankingSocios",

  components: {
    Check,
    B7RankingSociosLinhas,
  },

  props: {
    item: Object,
    index: Number,
    resultados: Object,
    linhaValida: Boolean,
    exibirTootipsConsultas: Boolean,
    gerandoPdf: Boolean,
  },

  data() {
    return {
      result: "valor",
      iconArrow: "/img/superbox/arrow-bottom.png",
      arrowControlPj: new Array(5).fill(false),
      valoresSociosPF: [
        // {
        //   cpf: this.resultados[
        //     `bk_103_004_empresa_${this.index + 1}_socio_pf_1_num_cpf`
        //   ],
        //   nome: this.resultados[
        //     `bk_103_004_empresa_${this.index + 1}_socio_pf_1_des_nome_socio`
        //   ],
        //   tempo:
        //     this.resultados[
        //       `bk_103_004_empresa_${this.index + 1}_socio_pf_1_tmp_vinculo_anos`
        //     ],
        //   checkAdm:
        //     this.resultados[
        //       `bk_103_004_empresa_${this.index + 1}_socio_pf_1_flag_socio_admin`
        //     ],
        //   relacao:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_1_flag_relcao_familiar`
        //     ],
        //   matchCep:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_1_flag_match_cep_socio_pf_1_socio_pf_1`
        //     ],
        //   semelhanca:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_1_flag_semelhanca_sobre_nome_socio_pf_1_socio_pf_1`
        //     ],
        // },
        // {
        //   cpf: this.resultados[
        //     `bk_103_004_empresa_${this.index + 1}_socio_pf_2_num_cpf`
        //   ],
        //   nome: this.resultados[
        //     `bk_103_004_empresa_${this.index + 1}_socio_pf_2_des_nome_socio`
        //   ],
        //   tempo:
        //     this.resultados[
        //       `bk_103_004_empresa_${this.index + 1}_socio_pf_2_tmp_vinculo_anos`
        //     ],
        //   checkAdm:
        //     this.resultados[
        //       `bk_103_004_empresa_${this.index + 1}_socio_pf_2_flag_socio_admin`
        //     ],
        //   relacao:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_2_flag_relcao_familiar`
        //     ],
        //   matchCep:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_2_flag_match_cep_socio_pf_1_socio_pf_2`
        //     ],
        //   semelhanca:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_2_flag_semelhanca_sobre_nome_socio_pf_1_socio_pf_2`
        //     ],
        // },
        // {
        //   cpf: this.resultados[
        //     `bk_103_004_empresa_${this.index + 1}_socio_pf_3_num_cpf`
        //   ],
        //   nome: this.resultados[
        //     `bk_103_004_empresa_${this.index + 1}_socio_pf_3_des_nome_socio`
        //   ],
        //   tempo:
        //     this.resultados[
        //       `bk_103_004_empresa_${this.index + 1}_socio_pf_3_tmp_vinculo_anos`
        //     ],
        //   checkAdm:
        //     this.resultados[
        //       `bk_103_004_empresa_${this.index + 1}_socio_pf_3_flag_socio_admin`
        //     ],
        //   relacao:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_3_flag_relcao_familiar`
        //     ],
        //   matchCep:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_3_flag_match_cep_socio_pf_1_socio_pf_3`
        //     ],
        //   semelhanca:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_3_flag_semelhanca_sobre_nome_socio_pf_1_socio_pf_3`
        //     ],
        // },
        // {
        //   cpf: this.resultados[
        //     `bk_103_004_empresa_${this.index + 1}_socio_pf_4_num_cpf`
        //   ],
        //   nome: this.resultados[
        //     `bk_103_004_empresa_${this.index + 1}_socio_pf_4_des_nome_socio`
        //   ],
        //   tempo:
        //     this.resultados[
        //       `bk_103_004_empresa_${this.index + 1}_socio_pf_4_tmp_vinculo_anos`
        //     ],
        //   checkAdm:
        //     this.resultados[
        //       `bk_103_004_empresa_${this.index + 1}_socio_pf_4_flag_socio_admin`
        //     ],
        //   relacao:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_4_flag_relcao_familiar`
        //     ],
        //   matchCep:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_4_flag_match_cep_socio_pf_1_socio_pf_4`
        //     ],
        //   semelhanca:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_4_flag_semelhanca_sobre_nome_socio_pf_1_socio_pf_4`
        //     ],
        // },
        // {
        //   cpf: this.resultados[
        //     `bk_103_004_empresa_${this.index + 1}_socio_pf_5_num_cpf`
        //   ],
        //   nome: this.resultados[
        //     `bk_103_004_empresa_${this.index + 1}_socio_pf_5_des_nome_socio`
        //   ],
        //   tempo:
        //     this.resultados[
        //       `bk_103_004_empresa_${this.index + 1}_socio_pf_5_tmp_vinculo_anos`
        //     ],
        //   checkAdm:
        //     this.resultados[
        //       `bk_103_004_empresa_${this.index + 1}_socio_pf_5_flag_socio_admin`
        //     ],
        //   relacao:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_5_flag_relcao_familiar`
        //     ],
        //   matchCep:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_5_flag_match_cep_socio_pf_1_socio_pf_5`
        //     ],
        //   semelhanca:
        //     this.resultados[
        //       `bk_103_004_empresa_${
        //         this.index + 1
        //       }_socio_pf_5_flag_semelhanca_sobre_nome_socio_pf_1_socio_pf_5`
        //     ],
        // },
      ],
    };
  },

  methods: {
    selecionaConsultaAdicional(documento) {
      this.abrirSelecaoConsulta(documento, "PF");
    },

    fechaConsultaAdicional() {
      this.fecharSelecaoConsulta();
    },

    formatarCPF(cpf) {
      if (cpf && cpf !== "-") {
        cpf = cpf.replace(/[^0-9*]/g, "");
        cpf = cpf.replace(
          /^(\d{0,3}|\*{0,3})(\d{0,3}|\*{0,3})(\d{0,3}|\*{0,3})(\d{0,2}|\*{0,2})$/,
          function (_, p1, p2, p3, p4) {
            return `${p1}.${p2}.${p3}-${p4}`;
          }
        );

        if (this.ocultarDados) {
          return cpf.replace(
            /(\d{3}|\*{3})\.(\d{3}|\*{3})\.(\d{3}|\*{3})-(\d{2}|\*{2})/,
            "***.$2.$3-**"
          );
        } else {
          return cpf;
        }
      }
      return cpf;
    },

    formatarNome(nome) {
      if (nome && nome != "-") {
        if (this.ocultarDados) {
          let palavras = nome.split(" ");

          if (palavras.length > 1) {
            for (let i = 1; i < palavras.length; i++) {
              palavras[i] = palavras[i].replace(/\S/g, "*");
            }
          }
          return palavras.join(" ");
        } else {
          return nome;
        }
      }
      return nome;
    },

    alternarDetalhes(i) {
      if (this.arrowControlPj[i]) {
        this.arrowControlPj[i] = false;
      } else {
        this.arrowControlPj[i] = true;
      }
    },

    async resetaValoresSocios() {
      this.valoresSociosPF = [];
    },

    atualizaValores() {
      const baseKey = `bk_103_004_empresa_${this.index + 1}_socio_pf_`;
      this.valoresSociosPF = Array.from({ length: 5 }, (_, i) => ({
        cpf: this.resultados[`${baseKey}${i + 1}_num_cpf`],
        nome: this.resultados[`${baseKey}${i + 1}_des_nome_socio`],
        tempo: this.resultados[`${baseKey}${i + 1}_tmp_vinculo_anos`],
        checkAdm: this.resultados[`${baseKey}${i + 1}_flag_socio_admin`],
        relacao: this.resultados[`${baseKey}${i + 1}_flag_relcao_familiar`],
        matchCep:
          this.resultados[
            `${baseKey}${i + 1}_flag_match_cep_socio_pf_1_socio_pf_${i + 1}`
          ],
        semelhanca:
          this.resultados[
            `${baseKey}${
              i + 1
            }_flag_semelhanca_sobre_nome_socio_pf_1_socio_pf_${i + 1}`
          ],
      }));
    },

    ...mapActions(dadosConsultaAddStore, [
      "abrirSelecaoConsulta",
      "fecharSelecaoConsulta",
    ]),
  },

  computed: {
    telaHistorico() {
      return this.$route.path.includes("/historico");
    },

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      ocultarDados: "ocultarDados",
    }),

    tooltipsBloco7() {
      return listaTooltips.bloco07_ranking3;
    },
  },

  async mounted() {
    await this.resetaValoresSocios();
    this.atualizaValores();
  },
};
</script>

<style lang="scss" scoped>
.boxSocios {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 50px;
}
.tabelapj {
  margin: 20px 0 0 0;
}

.linhaCabec {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.linhas {
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.principal {
  display: flex;
  width: 100%;
}
.cabec {
  padding: 1px 5px;
  background-color: rgb(55, 69, 129);
  // background-color: #44546a;
  color: white;
  border-radius: 5px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  cursor: default;
  line-height: 14px;

  &.verde {
    background-color: #18817e;
  }
  &.vermel {
    background-color: #d65532;
  }
  @include flex-center(column);
}
.results {
  height: 40px;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  cursor: default;
  padding: 5px;
  word-break: break-word;
  line-height: 14px;
  text-align: center;

  &.titulo {
    background-color: #d9e1f2;
    border: none;
    border-radius: 5px;
  }
  &.desativado {
    background-color: #d9d9d9 !important;
  }
  &.borda-desativada {
    border: solid 1px #999999 !important;
    color: #999999;
  }
  &.index0 {
    // color: #44546a;
    background-color: #44546a;
    color: white;
    font-weight: 600;
  }
}

.text-send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
}

.icon-send {
  color: #0070c0;
  width: 24px;
  font-size: 20px;
  cursor: pointer;
  transition: font-size 0.3s;

  &:hover {
    font-size: 22px;
  }
}

.disabled-title {
  background-color: #b9b9b9 !important;
  color: white;
}

.disabled-border {
  color: #a7a7a7;
  border: solid 1px #a7a7a7;
}

.overlay {
  position: fixed;
  height: 110vh;
  width: 110vw;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  left: 0;
  z-index: 9;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
