var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"resultsBox"},[_c('div',{staticClass:"resultLine"},[_c('div',{staticClass:"boxId sb-1",class:{
        'disabled-title': !_vm.linhaValida,
      }},[_vm._v(" 0"+_vm._s(_vm.index + 1)+" ")]),_c('div',{staticClass:"linhaRank",class:{
        'disabled-text': !_vm.linhaValida,
      },staticStyle:{"width":"130px"}},[_vm._v(" ------------------- ")]),_c('div',{staticClass:"titles sb-2 sb-right",class:{
        'disabled-title': !_vm.linhaValida,
      },attrs:{"id":'B7RankingLinhas-0'}},[_vm._v(" CNPJ ")]),(
        _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_cnpj`] != undefined &&
        _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_cnpj`] != '-'
      )?_c('div',{staticClass:"results sb-3 sb-right text-send"},[_vm._v(" "+_vm._s(_vm.formatarCNPJ(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_cnpj`]))+" "),(
          !_vm.telaHistorico &&
          !_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_cnpj`].includes('*') &&
          !_vm.gerandoPdf
        )?_c('span',{staticClass:"material-symbols-outlined icon-send",on:{"click":function($event){return _vm.selecionaConsultaAdicional(
            _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_cnpj`]
          )}}},[_vm._v(" send ")]):_vm._e()]):_c('div',{staticClass:"results sb-3 sb-right disabled-border"},[_vm._v(" Sem informações ")]),_c('div',{staticClass:"titles sb-2",class:{
        'disabled-title': !_vm.linhaValida,
      },attrs:{"id":'B7RankingLinhas-1'}},[_vm._v(" Capital Social ")]),(
        _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_vlr_capital_social`] !=
          undefined &&
        _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_vlr_capital_social`] !=
          '-'
      )?_c('div',{staticClass:"results sb-2"},[_vm._v(" "+_vm._s(_vm.formatarNumeroComPonto( _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_vlr_capital_social`] ))+" ")]):_c('div',{staticClass:"results sb-2 disabled-border"},[_vm._v("-")]),_c('div',{staticClass:"arrowBox",on:{"click":function($event){return _vm.alternarDetalhes()}}},[_c('img',{class:{
          'icon-arrow-aberto': _vm.arrowControl,
          'icon-arrow': !_vm.arrowControl,
          'btn-disabled':
            (_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_cnpj`] ==
              undefined ||
              _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_cnpj`] == '-') &&
            this.index != 0,
        },attrs:{"src":_vm.iconArrow}})])]),(_vm.arrowControl)?_c('div',{staticClass:"detalhes"},[_c('B7RankingDetalhes',{attrs:{"item":_vm.item,"index":_vm.index,"resultados":_vm.resultados,"linhaValida":_vm.linhaValida,"codigo_agrupador":_vm.codigo_agrupador,"resultadoFaturamentoLinha":_vm.resultadoFaturamentoLinha,"gerandoPdf":_vm.gerandoPdf,"exibirTootipsConsultas":_vm.exibirTootipsConsultas,"valorDataRef":_vm.valorDataRef}}),_c('B7RankingSocios',{attrs:{"item":_vm.item,"index":_vm.index,"resultados":_vm.resultados,"linhaValida":_vm.linhaValida,"gerandoPdf":_vm.gerandoPdf,"exibirTootipsConsultas":_vm.exibirTootipsConsultas}})],1):_vm._e(),_vm._l((_vm.tooltipsBloco7),function(tt,i){return _c('div',{key:i},[(_vm.index == 0 && _vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":tt.target,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_c('div',{domProps:{"innerHTML":_vm._s(tt.texto)}})]):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }