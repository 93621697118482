var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"linhas"},[_c('div',{staticClass:"principal"},[_c('div',{staticClass:"results titulo sb-1 sb-right sb-top",class:{ desativado: !_vm.socioPj || _vm.socioPj == '-' }},[_vm._v(" Sócio PJ "+_vm._s(_vm.indexPj + 1)+" ")]),_c('div',{staticClass:"results sb-2 sb-right sb-top",class:{ 'borda-desativada': !_vm.socioPj || _vm.socioPj == '-' }},[(_vm.socioPj && _vm.socioPj != '-')?_c('span',{staticClass:"text-send"},[_vm._v(" "+_vm._s(_vm.formatarCNPJ( _vm.resultados[ `bk_103_004_empresa_${this.index + 1}_socio_pj_${ this.indexPj + 1 }_num_cnpj` ] ))+" "),(!_vm.telaHistorico && !_vm.socioPj.includes('*') && !_vm.gerandoPdf)?_c('span',{staticClass:"material-symbols-outlined icon-send",on:{"click":function($event){return _vm.selecionaConsultaAdicional(_vm.socioPj)}}},[_vm._v(" send ")]):_vm._e()]):_vm._e(),(!_vm.socioPj || _vm.socioPj == '-')?_c('span',[_vm._v(" Sem informações ")]):_vm._e()]),_c('div',{staticClass:"results sb-3 sb-right sb-top",class:{ 'borda-desativada': !_vm.socioPj || _vm.socioPj == '-' }},[_vm._v(" "+_vm._s(_vm.resultados[ `bk_103_004_empresa_${this.index + 1}_socio_pj_${ this.indexPj + 1 }_des_cnae_principal` ])+" ")]),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{ 'borda-desativada': !_vm.socioPj || _vm.socioPj == '-' }},[_vm._v(" "+_vm._s(_vm.resultados[ `bk_103_004_empresa_${this.index + 1}_socio_pj_${ this.indexPj + 1 }_tmp_vinculos_anos` ])+" "),(
          _vm.resultados[
            `bk_103_004_empresa_${this.index + 1}_socio_pj_${
              this.indexPj + 1
            }_tmp_vinculos_anos`
          ] == 1
        )?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" ano ")]):_vm._e(),(
          _vm.resultados[
            `bk_103_004_empresa_${this.index + 1}_socio_pj_${
              this.indexPj + 1
            }_tmp_vinculos_anos`
          ] != '-' &&
          _vm.resultados[
            `bk_103_004_empresa_${this.index + 1}_socio_pj_${
              this.indexPj + 1
            }_tmp_vinculos_anos`
          ] > 1
        )?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" anos ")]):_vm._e()]),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{ 'borda-desativada': !_vm.socioPj || _vm.socioPj == '-' }},[_vm._v(" "+_vm._s(_vm.resultados[ `bk_103_004_empresa_${this.index + 1}_socio_pj_${ this.indexPj + 1 }_tmp_fundacao_anos` ])+" "),(
          _vm.resultados[
            `bk_103_004_empresa_${this.index + 1}_socio_pj_${
              this.indexPj + 1
            }_tmp_fundacao_anos`
          ] == 1
        )?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" ano ")]):_vm._e(),(
          _vm.resultados[
            `bk_103_004_empresa_${this.index + 1}_socio_pj_${
              this.indexPj + 1
            }_tmp_fundacao_anos`
          ] != '-' &&
          _vm.resultados[
            `bk_103_004_empresa_${this.index + 1}_socio_pj_${
              this.indexPj + 1
            }_tmp_fundacao_anos`
          ] > 1
        )?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" anos ")]):_vm._e()]),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{ 'borda-desativada': !_vm.socioPj || _vm.socioPj == '-' },attrs:{"id":'sociopj-tipo-' + _vm.index + _vm.indexPj}},[_vm._v(" "+_vm._s(_vm.naturezaJuridicaTratada)+" ")]),(
        this.resultados[
          `bk_103_004_empresa_${this.index + 1}_socio_pj_${
            this.indexPj + 1
          }_des_natureza_juridica`
        ] != '-' && _vm.exibirTootipsConsultas
      )?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":'sociopj-tipo-' + _vm.index + _vm.indexPj,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_vm._v(" "+_vm._s(this.resultados[ `bk_103_004_empresa_${this.index + 1}_socio_pj_${ this.indexPj + 1 }_des_natureza_juridica` ])+" ")]):_vm._e(),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{ 'borda-desativada': !_vm.socioPj || _vm.socioPj == '-' },attrs:{"id":'sociopj-capital-' + _vm.index + _vm.indexPj}},[_vm._v(" "+_vm._s(_vm.capitalFormatado( _vm.resultados[ `bk_103_004_empresa_${this.index + 1}_socio_pj_${ this.indexPj + 1 }_vlr_capital_social` ] ))+" ")]),(
        _vm.resultados[
          `bk_103_004_empresa_${this.index + 1}_socio_pj_${
            this.indexPj + 1
          }_vlr_capital_social`
        ] != 0 && _vm.exibirTootipsConsultas
      )?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":'sociopj-capital-' + _vm.index + _vm.indexPj,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_vm._v(" "+_vm._s(_vm.formatarNumeroComPonto( _vm.resultados[ `bk_103_004_empresa_${this.index + 1}_socio_pj_${ this.indexPj + 1 }_vlr_capital_social` ] ))+" ")]):_vm._e(),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{ 'borda-desativada': !_vm.socioPj || _vm.socioPj == '-' }},[_c('Check',{attrs:{"valor":_vm.resultados[
            `bk_103_004_empresa_${this.index + 1}_socio_pj_${
              this.indexPj + 1
            }_flag_situacao_especial`
          ]}})],1),_c('div',{staticClass:"arrowBox",class:{ 'btn-disabled': !_vm.socioPj || _vm.socioPj == '-' },on:{"click":function($event){return _vm.alternarDetalhes(_vm.indexPj)}}},[(!_vm.arrowControlPj)?_c('span',{staticClass:"material-symbols-outlined icon-arrow"},[_vm._v(" expand_circle_down ")]):_c('span',{staticClass:"material-symbols-outlined icon-arrow"},[_vm._v(" expand_circle_up ")])])],1),(_vm.arrowControlPj)?_c('div',{staticClass:"secundario"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"cabec sb-2 sb-right sb-top",attrs:{"id":'B7RankingSociosLinhas-0' + _vm.indexPj}},[_vm._v(" Razão Social ")]),_c('div',{staticClass:"results sb-8 sb-right sb-top"},[_vm._v(" "+_vm._s(_vm.resultados[ `bk_103_004_empresa_${this.index + 1}_socio_pj_${ this.indexPj + 1 }_des_razao_nome_fantasia` ])+" ")])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"cabec sb-2 sb-right sb-top",attrs:{"id":'B7RankingSociosLinhas-1' + _vm.indexPj}},[_vm._v(" Local ")]),_c('div',{staticClass:"results sb-8 sb-right sb-top"},[_vm._v(" "+_vm._s(_vm.resultados[ `bk_103_004_empresa_${this.index + 1}_socio_pj_${ this.indexPj + 1 }_des_local` ])+" ")])])]):_vm._e(),_vm._l((_vm.tooltipsBloco7Filtrado),function(tt,i){return _c('div',{key:i},[(_vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":tt.target + _vm.indexPj,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_c('div',{domProps:{"innerHTML":_vm._s(tt.texto)}})]):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }