<template>
  <div class="bodySec">
    <div class="rowSec bigger">
      <span class="titleBlue" :id="'B3PerfilCep-0'">
        Top 05 perfis de atividades econômicas da região do CEP principal
      </span>
    </div>
    <div class="rowSec bigger">
      <div class="tableCols">
        <div class="col">
          <span class="titleBlue dark" :id="'B3PerfilCep-1'">
            Visão CNAE: Seção
          </span>
        </div>
        <div class="col">
          <span class="titleBlue dark" :id="'B3PerfilCep-2'">
            Visão CNAE: Divisão
          </span>
        </div>
      </div>
    </div>
    <div class="rowSec bigger">
      <div class="tableCols">
        <div class="col sb-5">
          <div class="linhaCabec">
            <div
              class="cabecalho text-center"
              v-for="(indiceSuperior, indexSuperior) in indicesSuperiores"
              :key="indexSuperior"
              :id="'B3PerfilCep1-' + indexSuperior"
            >
              {{ indiceSuperior }}
            </div>
          </div>
          <div class="tabela-corpo">
            <div
              class="resultados-linha"
              ref="corpoLinhas"
              v-for="(secao, index) in cnaeSecao"
              :key="index"
            >
              <span class="textos-resultados text-center">
                {{ secao.rank }}
              </span>
              <span class="textos-resultados text-center">
                {{ secao.cod }}
              </span>
              <span class="textos-resultados text-center">
                {{ secao.qtd }}
              </span>
              <span class="textos-resultados text-center">
                {{ secao.rep }}
              </span>
              <span class="textos-resultados desc text-left">
                {{ secao.desc }}
              </span>
            </div>
          </div>
        </div>
        <div class="col sb-5">
          <div class="linhaCabec">
            <div
              class="cabecalho text-center"
              v-for="(indiceSuperior, indexSuperior) in indicesSuperiores"
              :key="indexSuperior"
              :id="'B3PerfilCep2-' + indexSuperior"
            >
              {{ indiceSuperior }}
            </div>
          </div>
          <div class="tabela-corpo">
            <div
              class="resultados-linha"
              ref="corpoLinhas"
              v-for="(cnaeValor, index) in cnaeDivisao"
              :key="index"
            >
              <div class="textos-resultados text-center">
                {{ cnaeValor.rank }}
              </div>
              <div class="textos-resultados text-center">
                {{ cnaeValor.cod }}
              </div>
              <div class="textos-resultados text-center">
                {{ cnaeValor.qtd }}
              </div>
              <div class="textos-resultados text-center">
                {{ cnaeValor.rep }}
              </div>
              <div class="textos-resultados desc text-left">
                {{ cnaeValor.desc }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="rowSec bigger">
      <div class="tableRows">
        <span class="titleBlue dark" :id="'B3PerfilCep-3'">
          Visão CNAE: Grupo
        </span>
        <div class="tabelaPerfilCep">
          <div class="linhaCabec">
            <div
              class="cabecalho"
              v-for="(indiceSuperior, indexSuperior) in indicesSuperiores"
              :key="indexSuperior"
              :class="{
                '-grupo desc text-left': indiceSuperior == 'Descrição',
                'sm text-center': indiceSuperior != 'Descrição',
              }"
              :id="'B3PerfilCep3-' + indexSuperior"
            >
              {{ indiceSuperior }}
            </div>
          </div>
          <tbody class="tabela-corpo">
            <div
              class="resultados-linha"
              ref="corpoLinhasGrupos"
              v-for="(cnaeValor, index) in cnaeGrupo"
              :key="index"
            >
              <div class="textos-resultados sm text-center">
                {{ cnaeValor.rank }}
              </div>
              <div class="textos-resultados sm text-center">
                {{ cnaeValor.cod }}
              </div>
              <div class="textos-resultados sm text-center">
                {{ cnaeValor.qtd }}
              </div>
              <div class="textos-resultados sm text-center">
                {{ cnaeValor.rep }}
              </div>
              <div class="textos-resultados desc -grupo text-left">
                {{ cnaeValor.desc }}
              </div>
            </div>
          </tbody>
        </div>
      </div>
    </div>
    <div v-if="abreBloco">
      <div v-for="(tt, i) in tooltipsBloco3" :key="i">
        <b-tooltip
          v-if="exibirTootipsConsultas"
          :target="tt.target"
          triggers="hover"
          placement="bottom"
          variant="dark"
          boundary="window"
          customClass="tooltip-custom"
        >
          <div v-html="tt.texto"></div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "B3PerfilCep",
  props: {
    index: Number,
    abreBloco: Boolean,
    resultados: Object,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      indicesSuperiores: ["Ranking", "Código", "Qtd", "Rep", "Descrição"],
      cnae: [
        {
          rank: "01",
          cod: this.resultados.bk_304_008_cnae_secao_codigo_1,
          qtd: this.resultados.bk_304_008_cnae_secao_qtde_1,
          rep: this.resultados.bk_304_008_cnae_secao_representacao_1,
          desc: this.resultados.bk_304_008_cnae_secao_descricao_1,
        },
      ],

      liberaTela: false,
    };
  },

  watch: {
    resultados(newValue, oldValue) {
      if (Object.keys(newValue).length !== 0) {
        this.setEqualHeight(this.$refs.corpoLinhas);
        this.setEqualHeight(this.$refs.corpoLinhasGrupos);
      }
    },
  },

  computed: {
    tooltipsBloco3() {
      return listaTooltips.bloco03_perfilCep;
    },

    cnaeSecao() {
      let secao = [];
      for (let i = 1; i <= 5; i++) {
        secao.push({
          rank: `0${i}`,
          cod: this.resultados[`bk_304_008_cnae_secao_codigo_${i}`],
          qtd: this.resultados[`bk_304_008_cnae_secao_qtde_${i}`],
          rep: this.resultados[`bk_304_008_cnae_secao_representacao_${i}`],
          desc: this.resultados[`bk_304_008_cnae_secao_descricao_${i}`],
        });
      }
      return secao;
    },

    cnaeDivisao() {
      let divisao = [];
      for (let i = 1; i <= 5; i++) {
        divisao.push({
          rank: `0${i}`,
          cod: this.resultados[`bk_304_008_cnae_divisao_codigo_${i}`],
          qtd: this.resultados[`bk_304_008_cnae_divisao_qtde_${i}`],
          rep: this.resultados[`bk_304_008_cnae_divisao_representacao_${i}`],
          desc: this.resultados[`bk_304_008_cnae_divisao_descricao_${i}`],
        });
      }
      return divisao;
    },

    cnaeGrupo() {
      let grupo = [];
      for (let i = 1; i <= 5; i++) {
        grupo.push({
          rank: `0${i}`,
          cod: this.resultados[`bk_304_008_cnae_grupo_codigo_${i}`],
          qtd: this.resultados[`bk_304_008_cnae_grupo_qtde_${i}`],
          rep: this.resultados[`bk_304_008_cnae_grupo_representacao_${i}`],
          desc: this.resultados[`bk_304_008_cnae_grupo_descricao_${i}`],
        });
      }
      return grupo;
    },
  },

  methods: {
    async setEqualHeight(elements) {
      let maxHeight = 40;

      await elements.forEach((el) => {
        el.style.height = "auto";
      });

      await elements.forEach((el) => {
        maxHeight = Math.max(maxHeight, el.clientHeight);
      });

      await elements.forEach((el) => {
        el.style.height = `${maxHeight}px`;
      });
    },
  },

  async mounted() {
    if (this.$refs.corpoLinhas) {
      await this.setEqualHeight(this.$refs.corpoLinhas);
    }
    if (this.$refs.corpoLinhasGrupos) {
      await this.setEqualHeight(this.$refs.corpoLinhasGrupos);
    }
  },
};
</script>

<style lang="scss" scoped>
.bodySec {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 950px;
}

.rowSec {
  display: flex;
  flex: 1;
  gap: 5px;
  width: 950px;
  align-items: flex-end;
  justify-content: center;

  &.bigger {
    width: 950px;
  }
}
.tableCols {
  display: flex;
  flex: 1;
  gap: 5px;
  margin-top: 5px;

  .col {
    padding: 0%;
  }
}
.tableRows {
  display: flex;
  flex: 1;
  gap: 5px;
  margin-top: 5px;
  flex-direction: column;
  margin-top: 20px;
}
.titleBlue {
  background-color: #0070c0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 45px;
  font-size: 12px;
  font-weight: 400;
  border-radius: 5px;
  cursor: default;
  line-height: 18px;

  &.dark {
    background-color: rgb(24, 129, 126) !important;
    height: 40px;
  }
}

.tabelaPerfilCep {
  display: flex;
  flex-direction: column;
}
.linhaCabec {
  background-color: #d9e1f2;
  display: flex;
  border-radius: 5px;
  height: 40px;
  width: 100%;
}

.tabela-corpo {
  display: flex;
  flex-direction: column;
}

.cabecalho {
  background-color: #d9e1f2;
  width: 15% !important;
  font-size: 12px;
  font-weight: 400;
  border-radius: 5px;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;

  &.sm {
    width: 10% !important;
  }

  &.desc {
    justify-content: flex-start;
    padding-left: 10px;
  }
}

.-grupo {
  width: 60% !important;
}

.resultados-linha {
  width: 100%;
  margin-top: 5px;
  border: solid 1px #0070c0 !important;
  border-radius: 10px;
  // height: 50px;
  display: flex;
}
.textos-resultados {
  width: 15%;
  // height: 37px;
  padding: 3px 3px !important;
  font-size: 12px;
  font-weight: 400;
  border: none;
  line-height: 14px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &.sm {
    width: 10% !important;
  }

  &.desc {
    width: 190px;
    text-align: left;
    padding-left: 10px !important;
    text-transform: uppercase;
    justify-content: flex-start;
  }
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
