<template>
  <div class="container-map">
    <div class="row-map">
      <div class="options-box sb-2 sb-right">
        <span
          class="titleBlue"
          :class="{
            'disabled-title': mapaEmpresas.length === 0,
          }"
        >
          Pontos de Visualização <br />
          (Brasil)
        </span>
        <span
          class="item"
          :class="{
            'disabled-border': mapaEmpresas.length === 0,
          }"
        >
          <span class="texto" :id="'B7Mapa-ativas'"> Ativas </span>
          <span class="check-box" :id="'B7Mapa-check-ativas'">
            <md-switch
              v-model="checkAtivas"
              @change="atualizaMapa()"
              class="sw"
              :disabled="
                mapaEmpresas.length === 0 || empresasAtivas.length == 0
              "
            >
            </md-switch>
          </span>
        </span>
        <span
          class="item"
          :class="{
            'disabled-border': mapaEmpresas.length === 0,
          }"
        >
          <span class="texto" :id="'B7Mapa-encerradas'"> Encerradas </span>
          <span class="check-box" :id="'B7Mapa-check-encerradas'">
            <md-switch
              v-model="checkEncerradas"
              @change="atualizaMapa()"
              class="sw"
              :disabled="
                mapaEmpresas.length === 0 || empresasEncerradas.length == 0
              "
            >
            </md-switch>
          </span>
        </span>
        <span
          class="item"
          :class="{
            'disabled-border': mapaEmpresas.length === 0,
          }"
        >
          <span class="texto" :id="'B7Mapa-esconder'">Esconder Filiais</span>
          <span class="check-box" :id="'B7Mapa-check-esconder'">
            <md-switch
              v-model="checkEscondeFiliais"
              @change="atualizaMapa()"
              class="sw"
              :disabled="
                mapaEmpresas.length === 0 || empresasFiliais.length == 0
              "
            >
            </md-switch>
          </span>
        </span>
        <span
          class="item"
          :class="{
            'disabled-border': mapaEmpresas.length === 0,
          }"
        >
          <span class="texto icon-box" :id="'B7Mapa-tipo'">
            Filtrar tipo
            <span class="material-icons icon" v-if="filtroTipos.length > 0">
              filter_alt
            </span>
          </span>
          <span class="select-box" :id="'B7Mapa-check-tipo'">
            <md-field class="selectFav">
              <md-select
                v-model="filtroTipos"
                :multiple="true"
                name="Filtrar tipo"
                id="FiltrarTipo"
                @input="atualizaMapa()"
              >
                <span v-for="(tipo, index) in opcoesFiltroTipo" :key="index">
                  <md-option
                    :value="tipo"
                    :disabled="!tiposDisponiveis.has(tipo)"
                    :class="{ disabled: !tiposDisponiveis.has(tipo) }"
                    style="white-space: wrap"
                  >
                    {{ tipo }}
                  </md-option>
                </span>
              </md-select>
            </md-field>
          </span>
        </span>
        <span
          class="item"
          :class="{
            'disabled-border': mapaEmpresas.length === 0,
          }"
        >
          <span class="texto icon-box" :id="'B7Mapa-porte'">
            Filtrar porte
            <span class="material-icons icon" v-if="filtroPortes.length > 0">
              filter_alt
            </span></span
          >
          <span class="select-box" :id="'B7Mapa-check-porte'">
            <md-field class="selectFav">
              <md-select
                v-model="filtroPortes"
                :multiple="true"
                name="Filtrar porte"
                id="FiltrarPorte"
                @input="atualizaMapa()"
              >
                <span v-for="(tipo, index) in opcoesFiltroPorte" :key="index">
                  <md-option
                    :value="tipo"
                    :disabled="!portesDisponiveis.has(tipo)"
                    :class="{ disabled: !portesDisponiveis.has(tipo) }"
                    style="white-space: wrap"
                  >
                    {{ tipo }}
                  </md-option>
                </span>
              </md-select>
            </md-field>
          </span>
        </span>

        <div class="pin-box">
          <span class="pin">
            <img :src="blue" alt="" />
            Matrizes
          </span>
          <span class="pin">
            <img :src="purple" alt="" />

            Filiais
          </span>
          <span class="pin">
            <img :src="red" alt="" />

            Encerradas
          </span>
        </div>
      </div>
      <div
        class="map-box"
        v-if="this.centro.coordenada != undefined && mapaEmpresas.length != 0"
      >
        <Maps
          :center="centro"
          :marcas="marcas"
          :poligonos="poligonos"
          :dimensoes="{ width: '785px', height: '430px' }"
          :zoom="zoomMapa"
          :gmapCircle="false"
          ref="mapComponent"
        />
      </div>
      <div
        class="map-img-box"
        v-if="mapaEmpresas.length == 0"
        style="width: 775px; height: 430px"
      >
        <img
          src="/img/superbox/mapa-brasil.png"
          alt=""
          class="map-img"
          style="width: 775px; height: 430px"
        />
      </div>
    </div>
    <div v-if="abreBloco">
      <div v-for="(tt, i) in tooltipsBloco7Mapa" :key="i">
        <b-tooltip
          v-if="exibirTootipsConsultas"
          :target="tt.target"
          triggers="hover"
          placement="bottom"
          variant="dark"
          boundary="window"
          customClass="tooltip-custom"
        >
          <div v-html="tt.texto"></div>
        </b-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapasService } from "@/services";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapActions, mapWritableState } from "pinia";

import Maps from "@/components/Maps.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";

export default {
  name: "B7Mapa",
  components: {
    Maps,
  },

  props: {
    enderecos: Array,
    index: Number,
    abreBloco: Boolean,
    codigo_agrupador: String,
    exibirTootipsConsultas: Boolean,
  },

  data() {
    return {
      checkAtivas: true,
      checkEncerradas: false,
      checkEscondeFiliais: false,
      filtroTipos: [],
      opcoesFiltroTipo: [
        "Limitada (LTDA)",
        "Sociedade Anônima (SA)",
        "Microempreendedor Individual (MEI)",
        "EIRELI",
        "Empresa Individual (EI)",
        "Sociedade Simples (SS)",
        "Outros",
      ],
      filtroPortes: [],
      opcoesFiltroPorte: [
        "Microempreendedor Individual (MEI)",
        "Microempresa (ME)",
        "Pequeno Porte (EPP)",
        "Médio ou Grande Porte",
        "Sem Enquadramento ou Outro",
      ],

      red: "/img/superbox/pin-vermelho.png",
      blue: "/img/superbox/pin-azul.png",
      green: "/img/superbox/pin-verde.png",
      yellow: "/img/superbox/pin-laranja.png",
      purple: "/img/superbox/pin-roxo.png",

      centro: {},
      marcas: [],
      poligonos: [],

      mapaEmpresas: [],
      zoomMapa: 4,
    };
  },

  watch: {},

  computed: {
    portesDisponiveis() {
      const portes = new Set();
      this.mapaEmpresas.forEach((empresa) => {
        if (empresa.porte_empresa && !portes.has(empresa.porte_empresa)) {
          portes.add(empresa.porte_empresa);
        }
      });
      return portes;
    },

    tiposDisponiveis() {
      const tipos = new Set();
      this.mapaEmpresas.forEach((empresa) => {
        if (empresa.tipo_empresa && !tipos.has(empresa.tipo_empresa)) {
          tipos.add(empresa.tipo_empresa);
        }
      });
      return tipos;
    },

    empresasAtivas() {
      return this.mapaEmpresas.filter((empresa) => empresa.flag_empresa_ativa);
    },

    empresasEncerradas() {
      return this.mapaEmpresas.filter(
        (empresa) => empresa.flag_empresa_encerrada
      );
    },

    empresasFiliais() {
      return this.mapaEmpresas.filter(
        (empresa) => !empresa.flag_empresa_matriz
      );
    },

    tooltipsBloco7Mapa() {
      return listaTooltips.bloco07_mapa;
    },

    ...mapWritableState(userPreferenceStore, {
      ocultarDados: "ocultarDados",
    }),
  },

  methods: {
    async configuraMapa() {
      // await this.adicionaMarcaCentro();

      this.mapaEmpresas = await mapasService.recuperaCoordenadasEmpresas(
        this.codigo_agrupador
      );

      this.mapaEmpresas.forEach((empresa) => {
        if (
          empresa.flag_empresa_matriz &&
          !empresa.flag_empresa_encerrada &&
          empresa.flag_empresa_ativa
        ) {
          empresa.iconColor = this.blue;
          var unidade = `Matriz <br/>`;
        } else if (
          !empresa.flag_empresa_matriz &&
          empresa.flag_empresa_ativa &&
          !empresa.flag_empresa_encerrada
        ) {
          empresa.iconColor = this.purple;
          var unidade = `Filial <br/>`;
        } else if (
          empresa.flag_empresa_encerrada &&
          !empresa.flag_empresa_ativa
        ) {
          empresa.iconColor = this.red;
          var unidade = `Encerrada <br/>`;
        } else {
          empresa.iconColor = this.blue;
        }

        let texto =
          `<b>Detalhes:</b> <br/> ` +
          `Empresa: ${this.formatarTexto(empresa.des_razao_social)} <br/>` +
          unidade +
          `Qtd filiais ativas: ${empresa.num_filiais_ativas} <br/>` +
          `Qtd filiais encerradas: ${empresa.num_filiais_encerradas} <br/>` +
          `Extensão territorial: ${empresa.extensao_territorial} <br/>` +
          `Tipo: ${empresa.tipo_empresa} <br/>` +
          `Porte: ${empresa.porte_empresa} <br/>` +
          `Capital Social: R$ ${this.formatarNumeroComPonto(
            empresa.vlr_capital_social
          )}`;
        empresa.descricao = texto;
      });

      this.atualizaMapa();
    },

    atualizaMapa() {
      let tempAtivas = [];
      let tempEncerradas = [];

      if (this.checkAtivas) {
        tempAtivas = this.mapaEmpresas.filter(
          (empresa) => empresa.flag_empresa_ativa
        );
      }
      if (this.checkEncerradas) {
        tempEncerradas = this.mapaEmpresas.filter(
          (empresa) => empresa.flag_empresa_encerrada
        );
      }

      let combinado = [...tempAtivas, ...tempEncerradas];
      let unicos = [];

      combinado.forEach((item) => {
        if (!unicos.some((unico) => unico === item)) {
          unicos.push(item);
        }
      });

      if (this.checkEscondeFiliais) {
        this.marcas = unicos.filter((empresa) => empresa.flag_empresa_matriz);
      } else {
        this.marcas = unicos;
      }

      if (this.filtroTipos.length > 0) {
        this.marcas = this.marcas.filter((empresa) =>
          this.filtroTipos.includes(empresa.tipo_empresa)
        );
      }
      if (this.filtroPortes.length > 0) {
        this.marcas = this.marcas.filter((empresa) =>
          this.filtroPortes.includes(empresa.porte_empresa)
        );
      }

      // this.adicionaMarcaCentro();
    },

    async adicionaMarcaCentro() {
      const centroIndex = this.marcas.findIndex(
        (marca) => marca.descricao === "Centro"
      );

      if (centroIndex === -1) {
        this.marcas.push({
          coordenada: this.centro.coordenada,
          iconColor: this.red,
          descricao: "Centro",
        });
      }
    },

    formatarNumeroComPonto(numero) {
      if (numero && numero != "-") {
        let numeroString = parseFloat(numero).toFixed(2).toString();
        numeroString = numeroString.replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },

    formatarNome(nome) {
      if (nome && nome != "-") {
        if (this.ocultarDados) {
          let palavras = nome.split(" ");

          if (palavras.length > 1) {
            for (let i = 1; i < palavras.length; i++) {
              palavras[i] = palavras[i].replace(/\S/g, "*");
            }
          }
          return palavras.join(" ");
        } else {
          return nome;
        }
      }
      return nome;
    },

    formatarTexto(texto) {
      if (texto != null && texto != "-") {
        if (this.ocultarDados) {
          texto = texto.replace(/[^ ]/g, "*");
        }
      }

      return texto;
    },

    async buscarCentro() {
      let tentativas = 0;
      const maximoTentativas = 10;
      const esperar = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

      while (
        this.centro.coordenada === undefined &&
        tentativas < maximoTentativas
      ) {
        try {
          this.centro = await mapasService.recuperaCoordenadasPrincipal(
            this.codigo_agrupador
          );
          if (this.centro.coordenada !== undefined) {
            break;
          }
        } catch (erro) {
        }

        await esperar(1500);

        tentativas++;
      }
    },
  },

  async mounted() {
    if (this.codigo_agrupador != "") {
      await this.buscarCentro()
    }

    await this.configuraMapa();

    if (
      this.empresasAtivas.length == 0 &&
      this.empresasEncerradas.length != 0
    ) {
      this.checkEncerradas = true;
    }

    if (this.mapaEmpresas.length === 0 || this.empresasAtivas.length == 0) {
      this.checkAtivas = false;
    }

    this.atualizaMapa();
  },
};
</script>

<style lang="scss" scoped>
.container-map {
  display: flex;
  flex-direction: column;
  margin: 0 5px 10px 5px;
}
.row-map {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.map-box {
  display: flex;
}

.titleBlue {
  background-color: #0070c0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  height: 80px;
  cursor: default;
  line-height: 14px;
}

.item {
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  margin-top: 5px;
  font-size: 12px;
  font-weight: 400;
  border: solid 1px #0070c0;
  color: #0070c0;
  cursor: default;
}

.texto {
  width: 50%;
  line-height: 14px;

  &.icon-box {
    display: flex;
    justify-content: space-between;
    margin-left: 16px;
    align-items: center;
    width: 93px;
  }

  .icon {
    fill: #0070c0;
  }
}

.select-box {
  width: 40px;
  display: flex;
  justify-content: flex-start;
}

.selectFav {
  width: 20px;
}

.sw {
  width: 50%;
  text-align: right;
  margin: 0 0 0 10px;
}

.pin-box {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
}

.pin {
  display: flex;
  align-items: center;
  font-size: 12px;
}

.disabled {
  background-color: #ebebeb !important;
  cursor: not-allowed;
}

.disabled-title {
  background-color: #a7a7a7;
  color: white;
}

.disabled-border {
  color: #a7a7a7;
  border: solid 1px #a7a7a7;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
