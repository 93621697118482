var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detalhesBox"},[_c('div',{staticClass:"linhasDetalhes"},[_c('div',{staticClass:"titles sb-2 sb-right",class:{
        'disabled-title': !_vm.linhaValida,
      },attrs:{"id":'B7RankingDetalhes-0'}},[_vm._v(" Razão Social ")]),_c('div',{staticClass:"results sb-9",class:{
        'disabled-border': !_vm.linhaValida,
      }},[_vm._v(" "+_vm._s(_vm.formatarTexto( _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_des_razao_social`] ))+" ")])]),_c('div',{staticClass:"linhasDetalhes"},[_c('div',{staticClass:"titles sb-2 sb-right",class:{
        'disabled-title': !_vm.linhaValida,
      },attrs:{"id":'B7RankingDetalhes-1'}},[_vm._v(" Nome Fantasia ")]),_c('div',{staticClass:"results sb-9",class:{
        'disabled-border': !_vm.linhaValida,
      }},[_vm._v(" "+_vm._s(_vm.formatarTexto( _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_des_nome_fantasia`] ))+" ")])]),_c('div',{staticClass:"linhasDetalhes"},[_c('div',{staticClass:"titles sb-2 sb-right",class:{
        'disabled-title': !_vm.linhaValida,
      },attrs:{"id":'B7RankingDetalhes-2'}},[_vm._v(" CNAE Principal ")]),_c('div',{staticClass:"results sb-right sb-4",class:{
        'disabled-border': !_vm.linhaValida,
      }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_cod_cnae_principal`])+" ")]),_c('div',{staticClass:"titles sb-1 sb-right",class:{
        'disabled-title': !_vm.linhaValida,
      },attrs:{"id":'B7RankingDetalhes-3'}},[_vm._v(" Concent. CNAE ")]),_c('div',{staticClass:"results sb-1 sb-right",class:{
        'disabled-border': !_vm.linhaValida,
      }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_qtd_concentracao_cnae`])+" ")]),_c('div',{staticClass:"titles sb-2 sb-right",class:{
        'disabled-title': !_vm.linhaValida,
      },attrs:{"id":'B7RankingDetalhes-4'}},[_vm._v(" Qtde CNAE's Secundários ")]),_c('div',{staticClass:"results sb-1",class:{
        'disabled-border': !_vm.linhaValida,
      }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_qtd_cnaes_secundarios`])+" ")])]),_c('div',{staticClass:"linhasDetalhes"},[_c('div',{staticClass:"rankCol sb-3 sb-right"},[_c('div',{staticClass:"titles sb-3",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-5'}},[_vm._v(" Tipo de Empresa ")]),_c('div',{staticClass:"results sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},_vm._l(([
            'Ltda',
            'SA',
            'MEI',
            'Eireli',
            'EI',
            'SS',
            'Outro',
          ]),function(opcao,j){return _c('span',{key:j,staticClass:"tabText",class:{
            selecionado: _vm.compararValores(
              _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_des_tipo_empresa`],
              opcao
            ),
            'nao-selecionado': !_vm.compararValores(
              _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_des_tipo_empresa`],
              opcao
            ),
          },attrs:{"id":'B7RankingDetalhes-5-' + j}},[_c('p',{staticClass:"tabTexts"},[_vm._v(" "+_vm._s(opcao)+" ")]),(j < 6)?_c('span',{staticStyle:{"color":"black"}},[_vm._v("|")]):_vm._e()])}),0)]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-6'}},[_vm._v(" Check Sócio Adm. ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_c('Check',{attrs:{"valor":_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_flag_socio_admin`]}})],1)]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-7'}},[_vm._v(" Ano de Fundação ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_num_ano_fundacao`])+" ")])]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-8'}},[_vm._v(" Tempo Fundação ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_tmp_fundacao_anos`])+" "),(
            _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_tmp_fundacao_anos`] ==
            1
          )?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" ano ")]):_vm._e(),(
            _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_tmp_fundacao_anos`] >
            1
          )?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" anos ")]):_vm._e()])]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-9'}},[_vm._v(" Ano de Vínculo ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_num_ano_vinculo`])+" ")])]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-10'}},[_vm._v(" Tempo Vínculo ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_tmp_vinculo_anos`])+" "),(
            _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_tmp_vinculo_anos`] ==
            1
          )?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" ano ")]):_vm._e(),(
            _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_tmp_vinculo_anos`] > 1
          )?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" anos ")]):_vm._e()])]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-11'}},[_vm._v(" Simples Nacional ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_c('Check',{attrs:{"valor":_vm.resultados[
              `bk_103_004_empresa_${_vm.index + 1}_flag_optante_simples_nacional`
            ]}})],1)]),_c('div',{staticClass:"rankCol sb-2"},[_c('div',{staticClass:"titles fundo-escuro sb-2",class:{
          'disabled-title': !_vm.linhaValida,
        },staticStyle:{"font-weight":"700"},attrs:{"id":'B7RankingDetalhes-12'}},[_vm._v(" Faturamento Presumido ")]),_c('div',{staticClass:"results sb-top sb-2",class:{
          'disabled-border': !_vm.linhaValida,
          fatur:
            !_vm.loadingFaturamento &&
            this.resultadoFaturamento == null &&
            _vm.linhaValida &&
            !_vm.telaHistorico &&
            !_vm.gerandoPdf,
        },attrs:{"id":'resultado-faturamento' + _vm.index}},[(
            !_vm.loadingFaturamento &&
            this.resultadoFaturamento == null &&
            _vm.linhaValida &&
            !_vm.telaHistorico &&
            !_vm.gerandoPdf
          )?_c('button',{staticClass:"button_sub",on:{"click":function($event){return _vm.carregarFaturamento(
              _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_cnpj`]
            )}}},[_vm._v(" Consultar "),_c('span',{staticClass:"material-symbols-outlined fatur-icon"},[_vm._v(" saved_search ")])]):_vm._e(),(
            _vm.linhaValida &&
            _vm.resultadoFaturamento == null &&
            (_vm.gerandoPdf || _vm.telaHistorico)
          )?_c('span',{staticClass:"fatur-aviso"},[_vm._v(" Não consultado ")]):_vm._e(),(_vm.loadingFaturamento && _vm.resultadoFaturamento == null)?_c('md-progress-spinner',{attrs:{"md-diameter":30,"md-stroke":2,"md-mode":"indeterminate"}}):_vm._e(),(!_vm.loadingFaturamento && _vm.resultadoFaturamento != null)?_c('span',[_vm._v(" "+_vm._s(_vm.formatarValorParaReais( _vm.resultadoFaturamento.atributos.faturamento_presumido ))+" ")]):_vm._e(),(!_vm.linhaValida)?_c('span',[_vm._v("-")]):_vm._e()],1),(_vm.resultadoFaturamento != null && _vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tt-text",attrs:{"target":'resultado-faturamento' + _vm.index,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[(_vm.resultadoFaturamento.atributos.faturamento_presumido == -1)?_c('span',[_vm._v(" "+_vm._s(_vm.resultadoFaturamento.atributos.elegibilidade)+" "),_c('br')]):_vm._e(),(_vm.resultadoFaturamento.atributos.faturamento_presumido > 999999)?_c('span',[_vm._v(" Estimativa anual: "),_c('br'),_vm._v(" R$ "+_vm._s(_vm.formatarNumeroComPonto( _vm.resultadoFaturamento.atributos.faturamento_presumido ))+",00 ")]):_vm._e()]):_vm._e()],1)]),_c('div',{staticClass:"linhasDetalhes"},[_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-13'}},[_vm._v(" Total de Filiais ")]),_c('div',{staticClass:"results sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_tqtd_total_filiais`])+" ")])]),_c('div',{staticClass:"rankCol sb-3 sb-right"},[_c('div',{staticClass:"titles sb-3",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-14'}},[_vm._v(" Extensão ")]),_c('div',{staticClass:"results sb-3 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},_vm._l((['Local ', 'Regional', 'Nacional']),function(opcao,j){return _c('span',{key:j,staticClass:"tabText",class:{
            selecionado: _vm.compararValores(
              _vm.resultados[
                `bk_103_004_empresa_${_vm.index + 1}_des_extensao_territorial`
              ],
              opcao
            ),
            'nao-selecionado': !_vm.compararValores(
              _vm.resultados[
                `bk_103_004_empresa_${_vm.index + 1}_des_extensao_territorial`
              ],
              opcao
            ),
          },attrs:{"id":'B7RankingDetalhes-14-' + j}},[_c('p',{staticClass:"tabTexts"},[_vm._v(" "+_vm._s(opcao)+" ")]),(j < 2)?_c('span',{staticStyle:{"color":"black"}},[_vm._v("|")]):_vm._e()])}),0)]),_c('div',{staticClass:"rankCol sb-2 sb-right"},[_c('div',{staticClass:"titles sb-2",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-15'}},[_vm._v(" Porte ")]),_c('div',{staticClass:"results sb-2 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},_vm._l((['MEI', 'ME ', 'EPP', 'Médio+']),function(opcao,j){return _c('span',{key:j,staticClass:"tabText",class:{
            selecionado: _vm.compararValores(
              _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_des_porte`],
              opcao
            ),

            'nao-selecionado': !_vm.compararValores(
              _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_des_porte`],
              opcao
            ),
          },attrs:{"id":'B7RankingDetalhes-15-' + j}},[_c('p',{staticClass:"tabTexts"},[_vm._v(" "+_vm._s(opcao)+" ")]),(j < 3)?_c('span',{staticStyle:{"color":"black"}},[_vm._v("|")]):_vm._e()])}),0)]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-16'}},[_vm._v(" Qtde de Sócios ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_qtd_socios`])+" ")])]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-17'}},[_vm._v(" Alteração QSA ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_c('Check',{attrs:{"valor":_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_flag_alteracao_qsa`]}})],1)]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-18'}},[_vm._v(" Recência QSA ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.formatarData( _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_dt_recencia_qsa`] ))+" ")])]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-19'}},[_vm._v(" Recência Status Ativa ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.formatarData( _vm.resultados[ `bk_103_004_empresa_${_vm.index + 1}_dt_recencia_status_ativa` ] ))+" ")])]),_c('div',{staticClass:"rankCol sb-1"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-20'}},[_vm._v(" Origem no Exterior ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_c('Check',{attrs:{"valor":_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_flag_origem_exterior`]}})],1)])]),_c('div',{staticClass:"linhasDetalhes"},[_c('table',[_c('thead',[_c('tr',{staticClass:"tableHead"},_vm._l((_vm.tableColumns),function(column,index){return _c('th',{key:column,staticClass:"titles",class:{
              'sb-1': index == 3 || index == 4,
              'sb-2': index == 0 || index == 2,
              'sb-5': index == 1,
              'sb-right': index != 4,
              'disabled-title': !_vm.linhaValida,
            },attrs:{"id":'B7RankingDetalhes-21-' + index}},[_vm._v(" "+_vm._s(column)+" ")])}),0)]),_c('tbody',_vm._l((_vm.detalhamentos),function(detalhamento,index){return _c('tr',{key:index,staticClass:"tableBody"},[_c('td',{staticClass:"results detail sb-2 sb-right sb-top",class:{
              'disabled-border': !_vm.linhaValida,
            },attrs:{"id":'B7RankingDetalhes-22-' + index}},[_vm._v(" "+_vm._s(detalhamento.valor)+" ")]),_c('td',{staticClass:"results detail sb-5 sb-right sb-top",class:{
              'disabled-border': !_vm.linhaValida,
            }},[_vm._v(" "+_vm._s(detalhamento.desc)+" ")]),_c('td',{staticClass:"results detail sb-2 sb-right sb-top",class:{
              'disabled-border': !_vm.linhaValida,
            }},[_vm._v(" "+_vm._s(_vm.formatarData(detalhamento.data))+" ")]),_c('td',{staticClass:"results detail sb-1 sb-right sb-top",class:{
              'disabled-border': !_vm.linhaValida,
            }},[_vm._v(" "+_vm._s(detalhamento.tempo)+" "),(detalhamento.tempo == 1)?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" ano ")]):_vm._e(),(detalhamento.tempo > 1)?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" anos ")]):_vm._e()]),_c('td',{staticClass:"results detail sb-1 sb-top",class:{
              'disabled-border': !_vm.linhaValida,
            }},[_c('Check',{attrs:{"valor":detalhamento.check}})],1)])}),0)])]),_c('div',{staticClass:"linhasDetalhes"},[_c('div',{staticClass:"rankCol sb-2 sb-right"},[_c('div',{staticClass:"titles sb-2",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-23'}},[_vm._v(" CEP da Matriz ")]),_c('div',{staticClass:"results sb-2 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.formatarCEP( _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_num_cep_matriz`] ))+" ")])]),_c('div',{staticClass:"rankCol sb-5 sb-right"},[_c('div',{staticClass:"titles sb-5",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-24'}},[_vm._v(" Logradouro ")]),_c('div',{staticClass:"results sb5 sb-top desc",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_des_logradouro_matriz`])+" ")])]),_c('div',{staticClass:"rankCol sb-3 sb-right"},[_c('div',{staticClass:"titles sb-3",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-25'}},[_vm._v(" Município ")]),_c('div',{staticClass:"results sb-3 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_des_municipio_matriz`])+" ")])]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-26'}},[_vm._v(" UF ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_des_uf_matriz`])+" ")])])]),_c('div',{staticClass:"linhasDetalhes"},[_c('div',{staticClass:"rankCol sb-2 sb-right"},[_c('div',{staticClass:"titles",class:{
          'disabled-title': !_vm.linhaValida,
        },staticStyle:{"padding-inline":"30px"},attrs:{"id":'B7RankingDetalhes-27'}},[_vm._v(" Precisão do CEP da Matriz ")]),_c('div',{staticClass:"results sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},_vm._l((['Único', 'Parcial', 'Múltiplo']),function(opcao,j){return _c('span',{key:j,staticClass:"tabText",class:{
            selecionado: _vm.compararValores(
              _vm.resultados[
                `bk_103_004_empresa_${_vm.index + 1}_precisao_cep_matriz`
              ],
              opcao
            ),

            'nao-selecionado': !_vm.compararValores(
              _vm.resultados[
                `bk_103_004_empresa_${_vm.index + 1}_precisao_cep_matriz`
              ],
              opcao
            ),
          },attrs:{"id":'B7RankingDetalhes-27-' + j}},[_c('p',{staticClass:"tabTexts"},[_vm._v(" "+_vm._s(opcao)+" ")]),(j < 2)?_c('span',{staticStyle:{"color":"black"}},[_vm._v("|")]):_vm._e()])}),0)]),_c('div',{staticClass:"rankCol sb-5 sb-right"},[_c('div',{staticClass:"titles sb-5",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-28'}},[_vm._v(" Classe do CEP da Matriz ")]),_c('div',{staticClass:"results sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},_vm._l(([
            'Padrão',
            'Especial',
            'Promocional',
            'Comunitário',
            'Correios',
          ]),function(opcao,j){return _c('span',{key:j,staticClass:"tabText",class:{
            selecionado: _vm.compararValores(
              _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_classe_cep_matriz`],
              opcao
            ),

            'nao-selecionado': !_vm.compararValores(
              _vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_classe_cep_matriz`],
              opcao
            ),
          },attrs:{"id":'B7RankingDetalhes-28-' + j}},[_c('p',{staticClass:"tabTexts"},[_vm._v(" "+_vm._s(opcao)+" ")]),(j < 4)?_c('span',{staticStyle:{"color":"black"}},[_vm._v("|")]):_vm._e()])}),0)]),_c('div',{staticClass:"rankCol sb-right sb-1"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-29'}},[_vm._v(" Dist. ao CEP Princ. ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_dist_km_cep_principal`])+" ")])]),_c('div',{staticClass:"rankCol sb-right sb-1"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-30'}},[_vm._v(" Viés QSA Familiar ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_c('Check',{attrs:{"valor":_vm.resultados[
              `bk_103_004_empresa_${_vm.index + 1}_flag_qsa_vies_familiar`
            ]}})],1)]),_c('div',{staticClass:"rankCol sb-right sb-1"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-31'}},[_vm._v(" Match c/ UF do CPF ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_c('Check',{attrs:{"valor":_vm.resultados[
              `bk_103_004_empresa_${_vm.index + 1}_flag_match_uf_cpf_matriz`
            ]}})],1)]),_c('div',{staticClass:"rankCols b-1"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-32'}},[_vm._v(" Match c/ Região ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_c('Check',{attrs:{"valor":_vm.resultados[
              `bk_103_004_empresa_${_vm.index + 1}_flag_match_regiao_cpf_matriz`
            ]}})],1)])]),_c('div',{staticClass:"linhasDetalhes"},[_c('div',{staticClass:"rankCol sb-2 sb-right"},[_c('div',{staticClass:"titles sb-2",class:{
          'disabled-title': !_vm.linhaValida,
        },staticStyle:{"padding":"1px 25px"},attrs:{"id":'B7RankingDetalhes-33'}},[_vm._v(" Distância ao Polo do CNAE ")]),_c('div',{staticClass:"results sb-2 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_distancia_polo_cnae_km`])+" ")])]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-34'}},[_vm._v(" Concent. CNAE Polo ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.resultados[ `bk_103_004_empresa_${_vm.index + 1}_qtd_concentracao_polo_cnae` ])+" ")])]),_c('div',{staticClass:"rankCol sb-5 sb-right"},[_c('div',{staticClass:"titles sb-5",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-35'}},[_vm._v(" Município do Polo ")]),_c('div',{staticClass:"results sb-5 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.resultados[ `bk_103_004_empresa_${_vm.index + 1}_des_municipio_polo_cnae` ])+" ")])]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-36'}},[_vm._v(" UF do Polo ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_vm._v(" "+_vm._s(_vm.resultados[`bk_103_004_empresa_${_vm.index + 1}_des_uf_polo_cnae`])+" ")])]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-37'}},[_vm._v(" Match c/ UF Polo ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_c('Check',{attrs:{"valor":_vm.resultados[
              `bk_103_004_empresa_${_vm.index + 1}_flag_match_uf_polo_cnae_matriz`
            ]}})],1)]),_c('div',{staticClass:"rankCol sb-1 sb-right"},[_c('div',{staticClass:"titles sb-1",class:{
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingDetalhes-38'}},[_vm._v(" Match c/ Região Polo ")]),_c('div',{staticClass:"results sb-1 sb-top",class:{
          'disabled-border': !_vm.linhaValida,
        }},[_c('Check',{attrs:{"valor":_vm.resultados[
              `bk_103_004_empresa_${
                _vm.index + 1
              }_flag_match_regiao_polo_cnae_matriz`
            ]}})],1)])]),_vm._l((_vm.tooltipsBloco7),function(tt,i){return _c('div',{key:i},[(_vm.index == 0 && _vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":tt.target,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_c('div',{domProps:{"innerHTML":_vm._s(tt.texto)}})]):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }