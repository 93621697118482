<template>
  <div class="rankBox" id="Empresas-ranking">
    <div class="cabecTab">
      <span class="cabecText sb-1 rank"> Ranking </span>
      <span class="cabecText sb-11 titulo">
        Top 05 maiores empresas do CPF consultado, conforme capital
        social
      </span>
    </div>
    <div class="resultBox">
      <B7RankingLinhas
        v-for="(item, index) in Array(5)"
        :key="index"
        :item="item"
        :index="index"
        :resultados="resultados"
        :codigo_agrupador="codigo_agrupador"
        :resultadosFaturamento="resultadosFaturamento"
        :gerandoPdf="gerandoPdf"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        :valorDataRef="valorDataRef"
      />
    </div>
  </div>
</template>

<script>
import B7RankingLinhas from "@/components/SuperBox/B7RankingLinhas.vue";

export default {
  name: "B7Ranking",

  components: { B7RankingLinhas },

  props: {
    resultados: Object,
    codigo_agrupador: String,
    resultadosFaturamento: Array,
    gerandoPdf: Boolean,
    exibirTootipsConsultas: Boolean,
    valorDataRef: String,
  },

  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.rankBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  cursor: default;
}
.cabecTab {
  display: flex;
  width: 100%;
  line-height: 14px;
}

.cabecText {
  display: flex;
  height: 45px;
  padding: 1px 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 400;
  background-color: #2f75b5;
  color: white;

  @include flex-center(column);

  &.titulo {
    margin-left: 5px;
  }
}
.resultTab {
  height: 37px;
  margin: 5px 5px 0 0;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
