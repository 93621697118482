<template>
  <div class="linhas">
    <div class="principal">
      <div
        class="results titulo sb-1 sb-right sb-top"
        :class="{ desativado: !socioPj || socioPj == '-' }"
      >
        Sócio PJ {{ indexPj + 1 }}
      </div>
      <div
        class="results sb-2 sb-right sb-top"
        :class="{ 'borda-desativada': !socioPj || socioPj == '-' }"
      >
        <span v-if="socioPj && socioPj != '-'" class="text-send">
          {{
            formatarCNPJ(
              resultados[
                `bk_103_004_empresa_${this.index + 1}_socio_pj_${
                  this.indexPj + 1
                }_num_cnpj`
              ]
            )
          }}
          <span
            v-if="!telaHistorico && !socioPj.includes('*') && !gerandoPdf"
            class="material-symbols-outlined icon-send"
            @click="selecionaConsultaAdicional(socioPj)"
          >
            send
          </span>
        </span>
        <span v-if="!socioPj || socioPj == '-'"> Sem informações </span>
      </div>
      <div
        class="results sb-3 sb-right sb-top"
        :class="{ 'borda-desativada': !socioPj || socioPj == '-' }"
      >
        {{
          resultados[
            `bk_103_004_empresa_${this.index + 1}_socio_pj_${
              this.indexPj + 1
            }_des_cnae_principal`
          ]
        }}
      </div>
      <div
        class="results sb-1 sb-right sb-top"
        :class="{ 'borda-desativada': !socioPj || socioPj == '-' }"
      >
        {{
          resultados[
            `bk_103_004_empresa_${this.index + 1}_socio_pj_${
              this.indexPj + 1
            }_tmp_vinculos_anos`
          ]
        }}
        <span
          v-if="
            resultados[
              `bk_103_004_empresa_${this.index + 1}_socio_pj_${
                this.indexPj + 1
              }_tmp_vinculos_anos`
            ] == 1
          "
          style="margin-left: 3px"
        >
          ano
        </span>
        <span
          v-if="
            resultados[
              `bk_103_004_empresa_${this.index + 1}_socio_pj_${
                this.indexPj + 1
              }_tmp_vinculos_anos`
            ] != '-' &&
            resultados[
              `bk_103_004_empresa_${this.index + 1}_socio_pj_${
                this.indexPj + 1
              }_tmp_vinculos_anos`
            ] > 1
          "
          style="margin-left: 3px"
        >
          anos
        </span>
      </div>
      <div
        class="results sb-1 sb-right sb-top"
        :class="{ 'borda-desativada': !socioPj || socioPj == '-' }"
      >
        {{
          resultados[
            `bk_103_004_empresa_${this.index + 1}_socio_pj_${
              this.indexPj + 1
            }_tmp_fundacao_anos`
          ]
        }}
        <span
          v-if="
            resultados[
              `bk_103_004_empresa_${this.index + 1}_socio_pj_${
                this.indexPj + 1
              }_tmp_fundacao_anos`
            ] == 1
          "
          style="margin-left: 3px"
        >
          ano
        </span>
        <span
          v-if="
            resultados[
              `bk_103_004_empresa_${this.index + 1}_socio_pj_${
                this.indexPj + 1
              }_tmp_fundacao_anos`
            ] != '-' &&
            resultados[
              `bk_103_004_empresa_${this.index + 1}_socio_pj_${
                this.indexPj + 1
              }_tmp_fundacao_anos`
            ] > 1
          "
          style="margin-left: 3px"
        >
          anos
        </span>
      </div>
      <div
        class="results sb-1 sb-right sb-top"
        :id="'sociopj-tipo-' + index + indexPj"
        :class="{ 'borda-desativada': !socioPj || socioPj == '-' }"
      >
        {{ naturezaJuridicaTratada }}
      </div>
      <b-tooltip
        v-if="
          this.resultados[
            `bk_103_004_empresa_${this.index + 1}_socio_pj_${
              this.indexPj + 1
            }_des_natureza_juridica`
          ] != '-' && exibirTootipsConsultas
        "
        :target="'sociopj-tipo-' + index + indexPj"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        {{
          this.resultados[
            `bk_103_004_empresa_${this.index + 1}_socio_pj_${
              this.indexPj + 1
            }_des_natureza_juridica`
          ]
        }}
      </b-tooltip>
      <div
        class="results sb-1 sb-right sb-top"
        :id="'sociopj-capital-' + index + indexPj"
        :class="{ 'borda-desativada': !socioPj || socioPj == '-' }"
      >
        {{
          capitalFormatado(
            resultados[
              `bk_103_004_empresa_${this.index + 1}_socio_pj_${
                this.indexPj + 1
              }_vlr_capital_social`
            ]
          )
        }}
      </div>
      <b-tooltip
        v-if="
          resultados[
            `bk_103_004_empresa_${this.index + 1}_socio_pj_${
              this.indexPj + 1
            }_vlr_capital_social`
          ] != 0 && exibirTootipsConsultas
        "
        :target="'sociopj-capital-' + index + indexPj"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        {{
          formatarNumeroComPonto(
            resultados[
              `bk_103_004_empresa_${this.index + 1}_socio_pj_${
                this.indexPj + 1
              }_vlr_capital_social`
            ]
          )
        }}
      </b-tooltip>
      <div
        class="results sb-1 sb-right sb-top"
        :class="{ 'borda-desativada': !socioPj || socioPj == '-' }"
      >
        <Check
          :valor="
            resultados[
              `bk_103_004_empresa_${this.index + 1}_socio_pj_${
                this.indexPj + 1
              }_flag_situacao_especial`
            ]
          "
        />
      </div>
      <div
        class="arrowBox"
        @click="alternarDetalhes(indexPj)"
        :class="{ 'btn-disabled': !socioPj || socioPj == '-' }"
      >
        <span
          class="material-symbols-outlined icon-arrow"
          v-if="!arrowControlPj"
        >
          expand_circle_down
        </span>
        <span class="material-symbols-outlined icon-arrow" v-else>
          expand_circle_up
        </span>
      </div>
    </div>
    <div class="secundario" v-if="arrowControlPj">
      <div class="row">
        <div
          class="cabec sb-2 sb-right sb-top"
          :id="'B7RankingSociosLinhas-0' + indexPj"
        >
          Razão Social
        </div>
        <div class="results sb-8 sb-right sb-top">
          {{
            resultados[
              `bk_103_004_empresa_${this.index + 1}_socio_pj_${
                this.indexPj + 1
              }_des_razao_nome_fantasia`
            ]
          }}
        </div>
      </div>
      <div class="row">
        <div
          class="cabec sb-2 sb-right sb-top"
          :id="'B7RankingSociosLinhas-1' + indexPj"
        >
          Local
        </div>
        <div class="results sb-8 sb-right sb-top">
          {{
            resultados[
              `bk_103_004_empresa_${this.index + 1}_socio_pj_${
                this.indexPj + 1
              }_des_local`
            ]
          }}
        </div>
      </div>
    </div>
    <div v-for="(tt, i) in tooltipsBloco7Filtrado" :key="i">
      <b-tooltip
        v-if="exibirTootipsConsultas"
        :target="tt.target + indexPj"
        triggers="hover"
        placement="bottom"
        variant="dark"
        class="tooltipTexts"
        boundary="window"
      >
        <div v-html="tt.texto"></div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import Check from "@/components/SuperBox/Check.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";
import { dadosConsultaAddStore } from "@/stores/dadosConsultaAdd";
import { userPreferenceStore } from "@/stores/userPreference";
import { mapActions, mapWritableState } from "pinia";

export default {
  name: "B7RankingSociosLinhas",

  components: {
    Check,
  },

  props: {
    item: Object,
    indexPj: Number,
    index: Number,
    exibirTootipsConsultas: Boolean,
    resultados: Object,
    gerandoPdf: Boolean,
  },

  data() {
    return {
      result: "valor",
      iconArrow: "/img/superbox/arrow-bottom.png",
      arrowControlPj: false,
    };
  },

  methods: {
    selecionaConsultaAdicional(documento) {
      this.abrirSelecaoConsulta(documento, "PJ");
    },

    fechaConsultaAdicional() {
      this.fecharSelecaoConsulta();
    },

    capitalFormatado(valor) {
      if (Math.abs(valor) >= 1000000000) {
        const valorEmBilhoes = (valor / 1000000000).toFixed(1);
        if (parseFloat(valorEmBilhoes) >= 2) {
          return valorEmBilhoes + " bilhões";
        } else {
          return valorEmBilhoes + " bilhão";
        }
      } else if (Math.abs(valor) >= 10000000) {
        const valorEmMilhoes = (valor / 1000000).toFixed(0);
        return valorEmMilhoes + " milhões";
      } else {
        return this.formatarNumeroComPonto(valor);
      }
    },

    formatarCNPJ(cnpj) {
      if (cnpj != null && cnpj != "-") {
        cnpj = cnpj.replace(/\D/g, "");
        cnpj = cnpj.replace(/^(\d{2})(\d)/, "$1.$2");
        cnpj = cnpj.replace(/^(\d{2})\.(\d{3})(\d)/, "$1.$2.$3");
        cnpj = cnpj.replace(/\.(\d{3})(\d)/, ".$1/$2");
        cnpj = cnpj.replace(/(\d{4})(\d)/, "$1-$2");
        if (this.ocultarDados) {
          cnpj = cnpj.replace(
            /(\d{2})\.(\d{3})\.(\d{3})\/(\d{4})\-(\d{2})/,
            (match, p1, p2, p3, p4, p5) => `${p1}.***.***/****-${p5}`
          );
        }
      }

      return cnpj;
    },

    formatarNumeroComPonto(numero) {
      if (numero != "-") {
        const numeroString = numero.toString().replace(".", ",");
        const partes = numeroString.split(",");
        partes[0] = partes[0].replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        return partes.join(",");
      } else {
        return numero;
      }
    },

    alternarDetalhes(i) {
      if (this.arrowControlPj) {
        this.arrowControlPj = false;
      } else {
        this.arrowControlPj = true;
      }
    },

    ...mapActions(dadosConsultaAddStore, [
      "abrirSelecaoConsulta",
      "fecharSelecaoConsulta",
    ]),
  },

  computed: {
    telaHistorico() {
      return this.$route.path.includes("/historico");
    },

    naturezaJuridicaTratada() {
      const valor =
        this.resultados[
          `bk_103_004_empresa_${this.index + 1}_socio_pj_${
            this.indexPj + 1
          }_des_natureza_juridica`
        ];
      if (valor.includes("(")) {
        const match = valor.match(/\((.*?)\)/);
        if (match) {
          return match[1];
        }
      }

      return valor;
    },

    tooltipsBloco7() {
      return listaTooltips.bloco07_ranking4;
    },

    tooltipsBloco7Filtrado() {
      if (this.arrowControlPj) {
        return this.tooltipsBloco7;
      } else {
        return [];
      }
    },

    socioPj() {
      return this.resultados[
        `bk_103_004_empresa_${this.index + 1}_socio_pj_${
          this.indexPj + 1
        }_num_cnpj`
      ];
    },

    ...mapWritableState(userPreferenceStore, {
      usuarioLogado: "usuarioLogado",
      ocultarDados: "ocultarDados",
    }),

    ...mapWritableState(dadosConsultaAddStore, {
      ativaTrilhaConsultas: "ativaTrilhaConsultas",
    }),
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.linhas {
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.principal {
  display: flex;
  width: 100%;
}

.secundario {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 0 10px 75px;
  padding: 0 0 10px 0;
  border-bottom: dashed 1px #0070c0;
}

.cabec {
  padding: 1px 6px;
  background-color: #d9e1f2;
  color: black;
  border-radius: 5px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  cursor: default;
  line-height: 14px;

  @include flex-center(column);
}
.results {
  height: 40px;
  border: solid 1px #0070c0;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  cursor: default;
  padding: 5px;
  word-break: break-word;
  line-height: 14px;
  text-align: center;

  &.titulo {
    background-color: #d9e1f2;
    border: none;
    border-radius: 5px;
  }

  &.desativado {
    background-color: #d9d9d9;
  }
  &.borda-desativada {
    border: solid 1px #999999 !important;
    color: #999999;
  }
}

.arrowBox {
  width: 20px;
  cursor: pointer;

  @include flex-center(column);

  .icon-arrow {
    width: 20px;
    height: 20px;
    color: #0070c0;
    font-size: 1.3rem;
  }
}

.text-send {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  gap: 5px;
}

.icon-send {
  color: #0070c0;
  width: 24px;
  font-size: 20px;
  cursor: pointer;
  transition: font-size 0.3s;
  line-height: 18px;

  &:hover {
    font-size: 22px;
  }
}

.btn-disabled {
  cursor: not-allowed !important;
  pointer-events: none !important;
  opacity: 0.5;
}
</style>
<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
