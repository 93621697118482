var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"boxSocios"},[_c('div',{staticClass:"tabelapf"},[_c('div',{staticClass:"linhaCabec"},_vm._l(([
          'Top 05 Sócios PF',
          'CPF',
          'Nome',
          'Tempo Vínculo',
          'Check Sócio Adm.',
          'Relação Familiar',
          'Match CEP',
          'Semelhança Sobrenome',
        ]),function(cab,j){return _c('div',{key:cab,staticClass:"cabec",class:{
          'sb-1': j != 2 && j != 1,
          'sb-2': j == 1,
          'sb-3': j == 2,
          'sb-right': j != 7,
          'disabled-title': !_vm.linhaValida,
        },attrs:{"id":'B7RankingSocios-0-' + j}},[_vm._v(" "+_vm._s(cab)+" ")])}),0),_c('div',{staticClass:"corpo"},_vm._l((_vm.valoresSociosPF),function(socioPf,i){return _c('div',{key:i,staticClass:"linhas"},[_c('div',{staticClass:"principal"},[_c('div',{staticClass:"results titulo sb-1 sb-right sb-top",class:{
              index0: i == 0,
              desativado: !socioPf.cpf || socioPf.cpf == '-',
            },attrs:{"id":'B7RankingSocios-1'}},[_vm._v(" Sócio PF "+_vm._s(i)+" ")]),_c('div',{staticClass:"results sb-2 sb-right sb-top",class:{
              'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
            }},[(socioPf.cpf && socioPf.cpf != '-')?_c('span',{staticClass:"text-send"},[_vm._v(" "+_vm._s(_vm.formatarCPF(socioPf.cpf))+" "),(
                  !_vm.telaHistorico && !socioPf.cpf.includes('*') && !_vm.gerandoPdf
                )?_c('span',{staticClass:"material-symbols-outlined icon-send",on:{"click":function($event){return _vm.selecionaConsultaAdicional(socioPf.cpf)}}},[_vm._v(" send ")]):_vm._e()]):_vm._e(),(!socioPf.cpf || socioPf.cpf == '-')?_c('span',[_vm._v(" Sem informações ")]):_vm._e()]),_c('div',{staticClass:"results sb-3 sb-right sb-top",class:{
              'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
            }},[_vm._v(" "+_vm._s(_vm.formatarNome(socioPf.nome))+" ")]),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{
              'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
            }},[_vm._v(" "+_vm._s(socioPf.tempo)+" "),(socioPf.tempo == 1)?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" ano ")]):_vm._e(),(socioPf.tempo != '-' && socioPf.tempo > 1)?_c('span',{staticStyle:{"margin-left":"3px"}},[_vm._v(" anos ")]):_vm._e()]),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{
              'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
            }},[_c('Check',{class:{
                'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
              },attrs:{"valor":socioPf.checkAdm}})],1),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{
              'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
            }},[(i != 0)?_c('Check',{attrs:{"valor":socioPf.relacao}}):_c('span',[_vm._v("-")])],1),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{
              'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
            }},[(i != 0)?_c('Check',{attrs:{"valor":socioPf.matchCep}}):_c('span',[_vm._v("-")])],1),_c('div',{staticClass:"results sb-1 sb-right sb-top",class:{
              'borda-desativada': !socioPf.cpf || socioPf.cpf == '-',
            }},[(socioPf.cpf != '-')?_c('span',[_vm._v(" "+_vm._s(socioPf.semelhanca)+"% ")]):_c('span',[_vm._v(" - ")])])])])}),0)]),_c('div',{staticClass:"tabelapj"},[_c('div',{staticClass:"linhaCabec"},_vm._l(([
          'Top 05 Sócios PJ',
          'CNPJ',
          'CNAE Principal',
          'Tempo Vínculo',
          'Tempo Fundação',
          'Tipo',
          'Capital Social',
          'Alerta de Situação',
        ]),function(cab,j){return _c('div',{key:cab,staticClass:"cabec verde",class:{
          'sb-1': j != 2 && j != 1,
          'sb-2': j == 1,
          'sb-3': j == 2,
          'sb-right': j != 7,
          'disabled-title': !_vm.linhaValida,
          vermel: j == 7,
        },attrs:{"id":'B7RankingSocios-2-' + j}},[_vm._v(" "+_vm._s(cab)+" ")])}),0),_c('div',{staticClass:"corpo"},_vm._l((Array(5)),function(item,indexPj){return _c('B7RankingSociosLinhas',{key:indexPj,attrs:{"item":item,"index":_vm.index,"indexPj":indexPj,"resultados":_vm.resultados,"gerandoPdf":_vm.gerandoPdf,"exibirTootipsConsultas":_vm.exibirTootipsConsultas}})}),1)]),_vm._l((_vm.tooltipsBloco7),function(tt,i){return _c('div',{key:i},[(_vm.index == 0 && _vm.exibirTootipsConsultas)?_c('b-tooltip',{staticClass:"tooltipTexts",attrs:{"target":tt.target,"triggers":"hover","placement":"bottom","variant":"dark","boundary":"window"}},[_c('div',{domProps:{"innerHTML":_vm._s(tt.texto)}})]):_vm._e()],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }